module.exports={
    "adult": {
        "name": "Womens",
        "code": "adult",
        "isJersey": true,
        "isPant": true
    },
    "youth": {
        "name": "Girls",
        "code": "youth",
        "isJersey": true,
        "isPant": true
    }
}