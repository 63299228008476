module.exports={
    "braid": {
        "code": "braid",
        "name": "Braid"
    },
    "customProJerseys": {
        "code": "customProJerseys",
        "name": "Custom Pro Jerseys"
    },
    "customCollegiateJerseys": {
        "code": "customCollegiateJerseys",
        "name": "Custom Collegiate Jerseys"
    },
    "customTravelballJerseys": {
        "code": "customTravelballJerseys",
        "name": "Custom Travelball Jerseys"
    },
    "sublimatedProJerseys": {
        "code": "sublimatedProJerseys",
        "name": "Sublimated Pro Jerseys"
    },
    "sublimatedCollegiateJerseys": {
        "code": "sublimatedCollegiateJerseys",
        "name": "Sublimated Collegiate Jerseys"
    },
    "sublimatedTravelballJerseys": {
        "code": "sublimatedTravelballJerseys",
        "name": "Sublimated Travelball Jerseys"
    },
    "fasttrackSublimatedJerseys": {
        "code": "fasttrackSublimatedJerseys",
        "name": "Fasttrack Sublimated Jerseys"
    },
    "customProPants": {
        "code": "customProPants",
        "name": "Custom Pro Pants"
    },
    "customCollegiatePants": {
        "code": "customCollegiatePants",
        "name": "Custom Collegiate Pants"
    },
    "sublimatedProPants": {
        "code": "sublimatedProPants",
        "name": "Sublimated Pro Pants"
    },
    "sublimatedCollegiatePants": {
        "code": "sublimatedCollegiatePants",
        "name": "Sublimated Collegiate Pants"
    },
    "sublimatedProShorts": {
        "code": "sublimatedProShorts",
        "name": "Sublimated Pro Shorts"
    },
    "sublimatedCollegiateShorts": {
        "code": "sublimatedCollegiateShorts",
        "name": "Sublimated Collegiate Shorts"
    },
    "sublimatedTravelballShorts": {
        "code": "sublimatedTravelballShorts",
        "name": "Sublimated Travelball Shorts"
    },
    "WB150": {
        "code": "WB150",
        "name": "WB150"
    },
    "WLNCH": {
        "code": "WLNCH",
        "name": "WLNCH"
    },
    "WBDP": {
        "code": "WBDP",
        "name": "WBDP"
    },
    "WJ150": {
        "code": "WJ150",
        "name": "WJ150"
    },
    "WMFF": {
        "code": "WMFF",
        "name": "WMFF"
    },
    "W2BM": {
        "code": "W2BM",
        "name": "W2BM"
    },
    "knit": {
        "code": "knit",
        "name": "Knit"
    },
    "ribKnit": {
        "code": "ribKnit",
        "name": "Rib Knit"
    },
    "sublimated": {
        "code": "sublimated",
        "name": "Sublimated"
    },
    "embroidery": {
        "code": "embroidery",
        "name": "Embroidery"
    },
    "RVT": {
        "code": "RVT",
        "name": "RVT"
    },
    "button": {
        "code": "button",
        "name": "Button"
    },
    "reflective": {
        "code": "reflective",
        "name": "Reflective"
    },
    "onlyWhite": {
        "code": "onlyWhite",
        "name": "Only White"
    },
    "onlyBlueGray": {
        "code": "onlyBlueGray",
        "name": "Only Blue Gray"
    },
    "WHBJ": {
        "code": "WHBJ",
        "name": "WHBJ"
    },
    "WYP": {
        "code": "WYP",
        "name": "WYP"
    }
}