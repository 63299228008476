module.exports={
    "stockJersey": {
        "code": "stockJersey",
        "name": "Stock Jersey"
    },
    "customJerseys": {
        "code": "customJerseys",
        "name": "Custom Jerseys"
    },
    "fasttrackSublimatedJerseys": {
        "code": "fasttrackSublimatedJerseys",
        "name": "Fasttrack Sublimated Jerseys"
    },
    "sublimatedTwoButtonJersey": {
        "code": "sublimatedTwoButtonJersey",
        "name": "Sublimated Two Button Jersey"
    },
    "sublimatedVNeckJersey": {
        "code": "sublimatedVNeckJersey",
        "name": "Sublimated V-Neck Jersey"
    },
    "sublimatedFullButtonJersey": {
        "code": "sublimatedFullButtonJersey",
        "name": "Sublimated Full Button Jersey"
    },
    "stockPants": {
        "code": "stockPants",
        "name": "Stock Pants"
    },
    "customPants": {
        "code": "customPants",
        "name": "Custom Pants"
    },
    "sublimatedPants": {
        "code": "sublimatedPants",
        "name": "Sublimated Pants"
    }
}