module.exports={
    "anaheim": {
        "name": "Anaheim",
        "code": "anaheim",
        "assetIds": {
            "1c": [
                "Rawlings-Anaheim"
            ],
            "2c": [],
            "3c": []
        },
        "lowercase": false,
        "asset": {
            "capHeight": "1000",
            "outline1": "65",
            "outline2": "130",
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": true,
            "playerName": true,
            "number": false,
            "watermarkText": true,
            "allText": true,
            "allNumber": false,
            "fasttrackText": false,
            "fasttrackNumber": false
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco"
        ]
    },
    "arizona": {
        "name": "Arizona",
        "code": "arizona",
        "assetIds": {
            "1c": [
                "Rawlings-Arizona"
            ],
            "2c": [],
            "3c": []
        },
        "lowercase": false,
        "asset": {
            "capHeight": "1000",
            "outline1": "65",
            "outline2": "130",
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": true,
            "playerName": true,
            "number": false,
            "watermarkText": true,
            "allText": true,
            "allNumber": false,
            "fasttrackText": false,
            "fasttrackNumber": false
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco"
        ]
    },
    "brushScript": {
        "name": "Brush Script",
        "code": "brushScript",
        "assetIds": {
            "1c": [
                "Rawlings-BrushScript"
            ],
            "2c": [],
            "3c": []
        },
        "lowercase": true,
        "asset": {
            "capHeight": "683",
            "outline1": "44",
            "outline2": "88",
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": true,
        "availableDecos": {
            "teamName": true,
            "playerName": true,
            "number": false,
            "watermarkText": true,
            "allText": true,
            "allNumber": false,
            "fasttrackText": false,
            "fasttrackNumber": false
        },
        "outlineSpacing": false,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco"
        ]
    },
    "connecticut": {
        "name": "Connecticut",
        "code": "connecticut",
        "assetIds": {
            "1c": [
                "Rawlings-Connecticut"
            ],
            "2c": [],
            "3c": []
        },
        "lowercase": false,
        "asset": {
            "capHeight": "1000",
            "outline1": "65",
            "outline2": "130",
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": true,
            "playerName": true,
            "number": false,
            "watermarkText": true,
            "allText": true,
            "allNumber": false,
            "fasttrackText": true,
            "fasttrackNumber": false
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco",
            "fasttrack",
            "tg",
            "tgYouth"
        ]
    },
    "fancy": {
        "name": "Fancy",
        "code": "fancy",
        "assetIds": {
            "1c": [
                "Rawlings-Fancy"
            ],
            "2c": [],
            "3c": []
        },
        "lowercase": false,
        "asset": {
            "capHeight": "1000",
            "outline1": "47",
            "outline2": "94",
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": true,
            "playerName": true,
            "number": false,
            "watermarkText": true,
            "allText": true,
            "allNumber": false,
            "fasttrackText": false,
            "fasttrackNumber": false
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco"
        ]
    },
    "fullBlock": {
        "name": "Full Block",
        "code": "fullBlock",
        "assetIds": {
            "1c": [
                "Rawlings-FullBlock"
            ],
            "2c": [],
            "3c": []
        },
        "lowercase": false,
        "asset": {
            "capHeight": "1625",
            "outline1": "85",
            "outline2": "170",
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": true,
            "playerName": true,
            "number": false,
            "watermarkText": true,
            "allText": true,
            "allNumber": false,
            "fasttrackText": true,
            "fasttrackNumber": false
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco",
            "fasttrack",
            "tg",
            "tgYouth"
        ]
    },
    "maryland": {
        "name": "Maryland",
        "code": "maryland",
        "assetIds": {
            "1c": [
                "Rawlings-Maryland"
            ],
            "2c": [],
            "3c": []
        },
        "lowercase": false,
        "asset": {
            "capHeight": "858",
            "outline1": "56",
            "outline2": "112",
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": true,
            "playerName": true,
            "number": false,
            "watermarkText": true,
            "allText": true,
            "allNumber": false,
            "fasttrackText": false,
            "fasttrackNumber": false
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco"
        ]
    },
    "method": {
        "name": "Method",
        "code": "method",
        "assetIds": {
            "1c": [
                "Rawlings-Method"
            ],
            "2c": [],
            "3c": []
        },
        "lowercase": false,
        "asset": {
            "capHeight": "1712",
            "outline1": "112",
            "outline2": "224",
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": true,
            "playerName": true,
            "number": false,
            "watermarkText": true,
            "allText": true,
            "allNumber": false,
            "fasttrackText": true,
            "fasttrackNumber": false
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco",
            "fasttrack",
            "tg",
            "tgYouth"
        ]
    },
    "miami": {
        "name": "Miami",
        "code": "miami",
        "assetIds": {
            "1c": [
                "Rawlings-Miami"
            ],
            "2c": [],
            "3c": []
        },
        "lowercase": false,
        "asset": {
            "capHeight": "1000",
            "outline1": "65",
            "outline2": "130",
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": true,
            "playerName": true,
            "number": false,
            "watermarkText": true,
            "allText": true,
            "allNumber": false,
            "fasttrackText": true,
            "fasttrackNumber": false
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco",
            "fasttrack",
            "tg",
            "tgYouth"
        ]
    },
    "oldEnglish": {
        "name": "Old English",
        "code": "oldEnglish",
        "assetIds": {
            "1c": [
                "Rawlings-OldEnglish"
            ],
            "2c": [],
            "3c": []
        },
        "lowercase": true,
        "asset": {
            "capHeight": "905",
            "outline1": "59",
            "outline2": "118",
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": true,
            "playerName": true,
            "number": false,
            "watermarkText": true,
            "allText": false,
            "allNumber": false,
            "fasttrackText": false,
            "fasttrackNumber": false
        },
        "outlineSpacing": true,
        "decoTypes": []
    },
    "plainBlock": {
        "name": "Plain Block",
        "code": "plainBlock",
        "assetIds": {
            "1c": [
                "Rawlings-PlainBlock"
            ],
            "2c": [],
            "3c": []
        },
        "lowercase": false,
        "asset": {
            "capHeight": "2048",
            "outline1": "103",
            "outline2": "206",
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": true,
            "playerName": true,
            "number": false,
            "watermarkText": true,
            "allText": true,
            "allNumber": false,
            "fasttrackText": false,
            "fasttrackNumber": false
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco"
        ]
    },
    "script": {
        "name": "Script",
        "code": "script",
        "assetIds": {
            "1c": [
                "Rawlings-Script"
            ],
            "2c": [],
            "3c": []
        },
        "lowercase": true,
        "asset": {
            "capHeight": "705",
            "outline1": "46",
            "outline2": "92",
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": true,
        "availableDecos": {
            "teamName": true,
            "playerName": true,
            "number": false,
            "watermarkText": true,
            "allText": true,
            "allNumber": false,
            "fasttrackText": true,
            "fasttrackNumber": false
        },
        "outlineSpacing": false,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco",
            "fasttrack",
            "tg",
            "tgYouth"
        ]
    },
    "proBlock": {
        "name": "Pro Block",
        "code": "proBlock",
        "assetIds": {
            "1c": [
                "Rawlings-ProBlock"
            ],
            "2c": [
                "Rawlings-ProBlock",
                "Rawlings-ProBlock-Outline1"
            ],
            "3c": []
        },
        "lowercase": false,
        "asset": {
            "capHeight": "1620",
            "outline1": "154",
            "outline2": "278",
            "forceOutline1": true,
            "spacing": "0"
        },
        "outlines": 1,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": true,
            "playerName": true,
            "number": false,
            "watermarkText": true,
            "allText": true,
            "allNumber": false,
            "fasttrackText": false,
            "fasttrackNumber": false
        },
        "outlineSpacing": true,
        "decoTypes": []
    },
    "monolith": {
        "name": "Monolith",
        "code": "monolith",
        "assetIds": {
            "1c": [
                "Rawlings-Monolith-1c-fill"
            ],
            "2c": [
                "Rawlings-Monolith-2c-fill",
                "Rawlings-Monolith-2c-outline1"
            ],
            "3c": [
                "Rawlings-Monolith-3c-fill",
                "Rawlings-Monolith-3c-outline1",
                "Rawlings-Monolith-3c-outline2"
            ]
        },
        "lowercase": false,
        "asset": {
            "capHeight": "700",
            "outline1": null,
            "outline2": null,
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": true,
            "playerName": true,
            "number": false,
            "watermarkText": true,
            "allText": true,
            "allNumber": false,
            "fasttrackText": true,
            "fasttrackNumber": false
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco",
            "fasttrack",
            "tg",
            "tgYouth"
        ]
    },
    "stinger": {
        "name": "Stinger",
        "code": "stinger",
        "assetIds": {
            "1c": [
                "Rawlings-Stinger-1c-fill"
            ],
            "2c": [
                "Rawlings-Stinger-2c-fill",
                "Rawlings-Stinger-2c-outline1"
            ],
            "3c": [
                "Rawlings-Stinger-3c-fill",
                "Rawlings-Stinger-3c-outline1",
                "Rawlings-Stinger-3c-outline2"
            ]
        },
        "lowercase": false,
        "asset": {
            "capHeight": "700",
            "outline1": null,
            "outline2": null,
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": true,
            "playerName": true,
            "number": false,
            "watermarkText": true,
            "allText": true,
            "allNumber": false,
            "fasttrackText": true,
            "fasttrackNumber": false
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco",
            "fasttrack",
            "tg",
            "tgYouth"
        ]
    },
    "rock": {
        "name": "Rock",
        "code": "rock",
        "assetIds": {
            "1c": [
                "Rawlings-Rock-1c-fill"
            ],
            "2c": [
                "Rawlings-Rock-2c-fill",
                "Rawlings-Rock-2c-outline1"
            ],
            "3c": [
                "Rawlings-Rock-3c-fill",
                "Rawlings-Rock-3c-outline1",
                "Rawlings-Rock-3c-outline2"
            ]
        },
        "lowercase": false,
        "asset": {
            "capHeight": "700",
            "outline1": null,
            "outline2": null,
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": true,
            "playerName": true,
            "number": false,
            "watermarkText": true,
            "allText": true,
            "allNumber": false,
            "fasttrackText": true,
            "fasttrackNumber": false
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco",
            "fasttrack",
            "tg",
            "tgYouth"
        ]
    },
    "anaheimNumber": {
        "name": "Anaheim",
        "code": "anaheimNumber",
        "assetIds": {
            "1c": [
                "Rawlings-Anaheim"
            ],
            "2c": [],
            "3c": []
        },
        "lowercase": false,
        "asset": {
            "capHeight": "1000",
            "outline1": "65",
            "outline2": "130",
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": false,
            "playerName": false,
            "number": true,
            "watermarkText": false,
            "allText": false,
            "allNumber": true,
            "fasttrackText": false,
            "fasttrackNumber": false
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco"
        ]
    },
    "arizona47": {
        "name": "Arizona 47",
        "code": "arizona47",
        "assetIds": {
            "1c": [
                "Rawlings-Arizona"
            ],
            "2c": [],
            "3c": []
        },
        "lowercase": false,
        "asset": {
            "capHeight": "1000",
            "outline1": "65",
            "outline2": "130",
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": false,
            "playerName": false,
            "number": true,
            "watermarkText": false,
            "allText": false,
            "allNumber": true,
            "fasttrackText": false,
            "fasttrackNumber": false
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco"
        ]
    },
    "brushScriptNumber": {
        "name": "Brush Script",
        "code": "brushScriptNumber",
        "assetIds": {
            "1c": [
                "Rawlings-BrushScript"
            ],
            "2c": [],
            "3c": []
        },
        "lowercase": false,
        "asset": {
            "capHeight": "683",
            "outline1": "44",
            "outline2": "88",
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": false,
            "playerName": false,
            "number": true,
            "watermarkText": false,
            "allText": false,
            "allNumber": true,
            "fasttrackText": false,
            "fasttrackNumber": false
        },
        "outlineSpacing": true,
        "decoTypes": [
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco"
        ]
    },
    "connecticutNumber": {
        "name": "Connecticut",
        "code": "connecticutNumber",
        "assetIds": {
            "1c": [
                "Rawlings-Connecticut"
            ],
            "2c": [],
            "3c": []
        },
        "lowercase": false,
        "asset": {
            "capHeight": "1000",
            "outline1": "65",
            "outline2": "130",
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": false,
            "playerName": false,
            "number": true,
            "watermarkText": false,
            "allText": false,
            "allNumber": true,
            "fasttrackText": false,
            "fasttrackNumber": true
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco",
            "fasttrack",
            "tg",
            "tgYouth"
        ]
    },
    "fancy47": {
        "name": "Fancy 47",
        "code": "fancy47",
        "assetIds": {
            "1c": [
                "Rawlings-Fancy"
            ],
            "2c": [],
            "3c": []
        },
        "lowercase": false,
        "asset": {
            "capHeight": "1000",
            "outline1": "47",
            "outline2": "94",
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": false,
            "playerName": false,
            "number": true,
            "watermarkText": false,
            "allText": false,
            "allNumber": true,
            "fasttrackText": false,
            "fasttrackNumber": false
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco"
        ]
    },
    "fullBlockNumber": {
        "name": "Full Block",
        "code": "fullBlockNumber",
        "assetIds": {
            "1c": [
                "Rawlings-FullBlock"
            ],
            "2c": [],
            "3c": []
        },
        "lowercase": false,
        "asset": {
            "capHeight": "1625",
            "outline1": "85",
            "outline2": "170",
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": false,
            "playerName": false,
            "number": true,
            "watermarkText": false,
            "allText": false,
            "allNumber": true,
            "fasttrackText": false,
            "fasttrackNumber": true
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco",
            "fasttrack",
            "tg",
            "tgYouth"
        ]
    },
    "marylandNumber": {
        "name": "Maryland",
        "code": "marylandNumber",
        "assetIds": {
            "1c": [
                "Rawlings-Maryland"
            ],
            "2c": [],
            "3c": []
        },
        "lowercase": false,
        "asset": {
            "capHeight": "858",
            "outline1": "56",
            "outline2": "112",
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": false,
            "playerName": false,
            "number": true,
            "watermarkText": false,
            "allText": false,
            "allNumber": true,
            "fasttrackText": false,
            "fasttrackNumber": false
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco"
        ]
    },
    "methodNumber": {
        "name": "Method",
        "code": "methodNumber",
        "assetIds": {
            "1c": [
                "Rawlings-Method"
            ],
            "2c": [],
            "3c": []
        },
        "lowercase": false,
        "asset": {
            "capHeight": "1712",
            "outline1": "112",
            "outline2": "224",
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": false,
            "playerName": false,
            "number": true,
            "watermarkText": false,
            "allText": false,
            "allNumber": true,
            "fasttrackText": false,
            "fasttrackNumber": true
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco",
            "fasttrack",
            "tg",
            "tgYouth"
        ]
    },
    "miami47": {
        "name": "Miami 47",
        "code": "miami47",
        "assetIds": {
            "1c": [
                "Rawlings-Miami"
            ],
            "2c": [],
            "3c": []
        },
        "lowercase": false,
        "asset": {
            "capHeight": "1000",
            "outline1": "65",
            "outline2": "130",
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": false,
            "playerName": false,
            "number": true,
            "watermarkText": false,
            "allText": false,
            "allNumber": true,
            "fasttrackText": false,
            "fasttrackNumber": true
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco",
            "fasttrack",
            "tg",
            "tgYouth"
        ]
    },
    "plainBlockNumber": {
        "name": "Plain Block",
        "code": "plainBlockNumber",
        "assetIds": {
            "1c": [
                "Rawlings-PlainBlock"
            ],
            "2c": [],
            "3c": []
        },
        "lowercase": false,
        "asset": {
            "capHeight": "2048",
            "outline1": "103",
            "outline2": "206",
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": false,
            "playerName": false,
            "number": true,
            "watermarkText": false,
            "allText": false,
            "allNumber": true,
            "fasttrackText": false,
            "fasttrackNumber": false
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco"
        ]
    },
    "proBlockNumber": {
        "name": "Pro Block",
        "code": "proBlockNumber",
        "assetIds": {
            "1c": [
                "Rawlings-ProBlock"
            ],
            "2c": [
                "Rawlings-ProBlock",
                "Rawlings-ProBlock-Outline1"
            ],
            "3c": []
        },
        "lowercase": false,
        "asset": {
            "capHeight": "1620",
            "outline1": "154",
            "outline2": "278",
            "forceOutline1": true,
            "spacing": "0"
        },
        "outlines": 1,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": false,
            "playerName": false,
            "number": true,
            "watermarkText": false,
            "allText": false,
            "allNumber": false,
            "fasttrackText": false,
            "fasttrackNumber": false
        },
        "outlineSpacing": true,
        "decoTypes": []
    },
    "monolithNumber": {
        "name": "Monolith",
        "code": "monolithNumber",
        "assetIds": {
            "1c": [
                "Rawlings-Monolith-1c-fill"
            ],
            "2c": [
                "Rawlings-Monolith-2c-fill",
                "Rawlings-Monolith-2c-outline1"
            ],
            "3c": [
                "Rawlings-Monolith-3c-fill",
                "Rawlings-Monolith-3c-outline1",
                "Rawlings-Monolith-3c-outline2"
            ]
        },
        "lowercase": false,
        "asset": {
            "capHeight": "700",
            "outline1": null,
            "outline2": null,
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": false,
            "playerName": false,
            "number": true,
            "watermarkText": false,
            "allText": false,
            "allNumber": true,
            "fasttrackText": false,
            "fasttrackNumber": true
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco",
            "fasttrack",
            "tg",
            "tgYouth"
        ]
    },
    "stingerNumber": {
        "name": "Stinger",
        "code": "stingerNumber",
        "assetIds": {
            "1c": [
                "Rawlings-Stinger-1c-fill"
            ],
            "2c": [
                "Rawlings-Stinger-2c-fill",
                "Rawlings-Stinger-2c-outline1"
            ],
            "3c": [
                "Rawlings-Stinger-3c-fill",
                "Rawlings-Stinger-3c-outline1",
                "Rawlings-Stinger-3c-outline2"
            ]
        },
        "lowercase": false,
        "asset": {
            "capHeight": "700",
            "outline1": null,
            "outline2": null,
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": false,
            "playerName": false,
            "number": true,
            "watermarkText": false,
            "allText": false,
            "allNumber": true,
            "fasttrackText": false,
            "fasttrackNumber": true
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco",
            "fasttrack",
            "tg",
            "tgYouth"
        ]
    },
    "rockNumber": {
        "name": "Rock",
        "code": "rockNumber",
        "assetIds": {
            "1c": [
                "Rawlings-Rock-1c-fill"
            ],
            "2c": [
                "Rawlings-Rock-2c-fill",
                "Rawlings-Rock-2c-outline1"
            ],
            "3c": [
                "Rawlings-Rock-3c-fill",
                "Rawlings-Rock-3c-outline1",
                "Rawlings-Rock-3c-outline2"
            ]
        },
        "lowercase": false,
        "asset": {
            "capHeight": "700",
            "outline1": null,
            "outline2": null,
            "forceOutline1": false,
            "spacing": "0"
        },
        "outlines": 2,
        "canHaveTailing": false,
        "availableDecos": {
            "teamName": false,
            "playerName": false,
            "number": true,
            "watermarkText": false,
            "allText": false,
            "allNumber": true,
            "fasttrackText": false,
            "fasttrackNumber": true
        },
        "outlineSpacing": true,
        "decoTypes": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "pantDeco",
            "fasttrack",
            "tg",
            "tgYouth"
        ]
    }
}