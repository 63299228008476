module.exports={
    "none": {
        "code": "none",
        "abbr": "NONE",
        "name": "None",
        "colorCount": 0,
        "whitelists": {
            "collar": true,
            "cuff": true,
            "RIBWST": true
        }
    },
    "1inch1color": {
        "code": "1inch1color",
        "abbr": "1\"1C",
        "name": "1\" Pattern 1 (1-Color)",
        "colorCount": 1,
        "whitelists": {
            "collar": true,
            "cuff": true,
            "RIBWST": false
        }
    },
    "1inch3color": {
        "code": "1inch3color",
        "abbr": "1\"3C",
        "name": "1\" Pattern 3 (3-Color)",
        "colorCount": 3,
        "whitelists": {
            "collar": true,
            "cuff": true,
            "RIBWST": false
        }
    },
    "2inch1color": {
        "code": "2inch1color",
        "abbr": "2\"1C",
        "name": "2\" Pattern 1 (1-Color)",
        "colorCount": 1,
        "whitelists": {
            "collar": false,
            "cuff": true,
            "RIBWST": true
        }
    },
    "2inch3color": {
        "code": "2inch3color",
        "abbr": "2\"3C",
        "name": "2\" Pattern 3 (3-Color)",
        "colorCount": 3,
        "whitelists": {
            "collar": false,
            "cuff": true,
            "RIBWST": true
        }
    }
}