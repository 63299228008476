module.exports={
    "none": {
        "code": "none",
        "name": "None",
        "hex": null,
        "isEnabled": true,
        "hexWatermark": null,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "B"
    },
    "AF": {
        "hexStitching": "#4DC3FF",
        "hexWatermark": "#1A95D4",
        "code": "AF",
        "name": "A.F.A. Blue",
        "hex": "#0089cf",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "AQ": {
        "hexStitching": "#4AC9AE",
        "hexWatermark": "#206C5C",
        "code": "AQ",
        "name": "Aqua",
        "hex": "#247866",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "AZ": {
        "hexStitching": "#E9B91C",
        "hexWatermark": null,
        "code": "AZ",
        "name": "Aztec Gold",
        "hex": "#83670d",
        "isEnabled": false,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "B"
    },
    "B": {
        "hexStitching": "#474747",
        "hexWatermark": "#424242",
        "code": "B",
        "name": "Black",
        "hex": "#131313",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "BG": {
        "hexStitching": "#92AAA8",
        "hexWatermark": "#3A3E3E",
        "code": "BG",
        "name": "Blue Grey",
        "hex": "#c1cecd",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "B"
    },
    "BO": {
        "hexStitching": "#E69B84",
        "hexWatermark": "#D07153",
        "code": "BO",
        "name": "Burnt Orange",
        "hex": "#c44d28",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "B"
    },
    "C": {
        "hexStitching": "#B50816",
        "hexWatermark": "#3B0207",
        "code": "C",
        "name": "Cardinal",
        "hex": "#4a0309",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "CAB": {
        "hexStitching": "#4C718A",
        "hexWatermark": null,
        "code": "CAB",
        "name": "Carolina Blue",
        "hex": "#608ba8",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "CB": {
        "hexStitching": "#4698B9",
        "hexWatermark": "#81B9CF",
        "code": "CB",
        "name": "Columbia Blue",
        "hex": "#73b1ca",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "CMB": {
        "hexStitching": "#D68100",
        "hexWatermark": "#5D3800",
        "code": "CMB",
        "name": "Camo Brown",
        "hex": "#673e00",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "CMG": {
        "hexStitching": "#00B34A",
        "hexWatermark": "#003717",
        "code": "CMG",
        "name": "Camo Green",
        "hex": "#00451d",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "CMT": {
        "hexStitching": "#C4BCAB",
        "hexWatermark": "#A59981",
        "code": "CMT",
        "name": "Camo Tan",
        "hex": "#8e7f62",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "DB": {
        "hexStitching": "#9F521E",
        "hexWatermark": "#321A0A",
        "code": "DB",
        "name": "Dark Brown",
        "hex": "#3f200c",
        "isEnabled": false,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "DG": {
        "hexStitching": "#00D154",
        "hexWatermark": "#005823",
        "code": "DG",
        "name": "Dark Green",
        "hex": "#006227",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "DY": {
        "hexStitching": "#81949C",
        "hexWatermark": null,
        "code": "DY",
        "name": "Dodger Grey",
        "hex": "#a9b6bc",
        "isEnabled": false,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "FLB": {
        "hexStitching": "#38AFFF",
        "hexWatermark": "#1A80C3",
        "code": "FLB",
        "name": "Fluorescent Blue",
        "hex": "#0072bc",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "FLG": {
        "hexStitching": "#6EA937",
        "hexWatermark": "#7DB349",
        "code": "FLG",
        "name": "Fluorescent Green",
        "hex": "#8bc751",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "B"
    },
    "GR": {
        "hexStitching": "#858881",
        "hexWatermark": "#171716",
        "code": "GR",
        "name": "Graphite",
        "hex": "#4b4d49",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "DSW": {
        "hexStitching": "#606885",
        "hexWatermark": null,
        "code": "DSW",
        "name": "Dark Shadow",
        "hex": "#323645",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "HP": {
        "hexStitching": "#EB1467",
        "hexWatermark": "#F271A2",
        "code": "HP",
        "name": "Hot Pink",
        "hex": "#ef4d8b",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "K": {
        "hexStitching": "#27F189",
        "hexWatermark": "#098A47",
        "code": "K",
        "name": "Kelly Green",
        "hex": "#0a994f",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "KG": {
        "hexStitching": "#27F189",
        "hexWatermark": "#098A47",
        "code": "KG",
        "name": "Kelly Green",
        "hex": "#0a994f",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "LG": {
        "hexStitching": "#D49902",
        "hexWatermark": "#E4A710",
        "code": "LG",
        "name": "Light Gold",
        "hex": "#fdba12",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "B"
    },
    "LIME": {
        "hexStitching": "#E7FC5F",
        "hexWatermark": "#ADC605",
        "code": "LIME",
        "name": "Lime Green",
        "hex": "#c0dc05",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "LP": {
        "hexStitching": "#F47B8B",
        "hexWatermark": "#FBD3D8",
        "code": "LP",
        "name": "Light Pink",
        "hex": "#fbced4",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "B"
    },
    "LT": {
        "hexStitching": "#CA9D21",
        "hexWatermark": "#CBA541",
        "code": "LT",
        "name": "Light Old Gold",
        "hex": "#e1b748",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "B"
    },
    "MA": {
        "hexStitching": "#940019",
        "hexWatermark": null,
        "code": "MA",
        "name": "Maroon",
        "hex": "#290007",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "DM": {
        "hexStitching": "#940019",
        "hexWatermark": "#543339",
        "code": "DM",
        "name": "Dark Maroon",
        "hex": "#290007",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "MB": {
        "hexStitching": "#14FFE8",
        "hexWatermark": "#008C7E",
        "code": "MB",
        "name": "Marlin Blue",
        "hex": "#009c8c",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "N": {
        "hexStitching": "#2A6189",
        "hexWatermark": "#41525F",
        "code": "N",
        "name": "Navy",
        "hex": "#112737",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "OG": {
        "hexStitching": "#F7BE2B",
        "hexWatermark": "#976D05",
        "code": "OG",
        "name": "Old Gold",
        "hex": "#a87906",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "B"
    },
    "P": {
        "hexStitching": "#FFD06B",
        "hexWatermark": "#FFF2D6",
        "code": "P",
        "name": "Putty",
        "hex": "#ffedc5",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                0
            ]
        },
        "altColor": "B"
    },
    "PL": {
        "hexStitching": "#879EB5",
        "hexWatermark": null,
        "code": "PL",
        "name": "Platinum",
        "hex": "#bbc8d5",
        "isEnabled": false,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "PU": {
        "hexStitching": "#702BCA",
        "hexWatermark": "#4E2D78",
        "code": "PU",
        "name": "Purple",
        "hex": "#3a1669",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "R": {
        "hexStitching": "#6481D8",
        "hexWatermark": "#3D57A6",
        "code": "R",
        "name": "Royal",
        "hex": "#27449c",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "S": {
        "hexStitching": "#EB0A11",
        "hexWatermark": "#560406",
        "code": "S",
        "name": "Scarlet",
        "hex": "#7b0509",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "SG": {
        "hexStitching": "#ABA287",
        "hexWatermark": "#66635A",
        "code": "SG",
        "name": "Silver Grey",
        "hex": "#cbc5b4",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "B"
    },
    "TE": {
        "hexStitching": "#29F5E0",
        "hexWatermark": "#079283",
        "code": "TE",
        "name": "Teal",
        "hex": "#08a292",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "W"
    },
    "TO": {
        "hexStitching": "#C65915",
        "hexWatermark": "#ED9056",
        "code": "TO",
        "name": "Tennessee Orange",
        "hex": "#e9742c",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "B"
    },
    "TN": {
        "hexStitching": "#C65915",
        "hexWatermark": "#ED9056",
        "code": "TN",
        "name": "Tennessee Orange",
        "hex": "#e9742c",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "B"
    },
    "TX": {
        "hexStitching": "#DB8A61",
        "hexWatermark": "#A75E3A",
        "code": "TX",
        "name": "Texas Orange",
        "hex": "#9d4c24",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "B"
    },
    "VG": {
        "hexStitching": "#C19C57",
        "hexWatermark": "#C0A97F",
        "code": "VG",
        "name": "Vegas Gold",
        "hex": "#d5bc8d",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "B"
    },
    "W": {
        "hexStitching": "#CCCCCC",
        "hexWatermark": "#B3B3B3",
        "code": "W",
        "name": "White",
        "hex": "#ffffff",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                -15,
                0
            ],
            "3color": [
                -30,
                -15,
                0
            ]
        },
        "altColor": "B"
    },
    "YLW": {
        "hexStitching": "#CCC200",
        "hexWatermark": "#CCC200",
        "code": "YLW",
        "name": "Yellow",
        "hex": "#fff200",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "B"
    },
    "flannel": {
        "hexStitching": "#8A8A8A",
        "hexWatermark": null,
        "code": "flannel",
        "name": "Flannel",
        "hex": "#aaaaaa",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "B"
    },
    "REF": {
        "hexStitching": "#A49A8E",
        "hexWatermark": null,
        "code": "REF",
        "name": "Reflective Gray",
        "hex": "#c7c1b9",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "B"
    },
    "TR": {
        "hexStitching": "#EC8389",
        "hexWatermark": "#91161D",
        "code": "TR",
        "name": "True Red",
        "hex": "#cf2029",
        "isEnabled": true,
        "autoLightness": {
            "2color": [
                0,
                15
            ],
            "3color": [
                0,
                15,
                30
            ]
        },
        "altColor": "B"
    }
}