module.exports={
    "toggles": {
        "all": [
            "on",
            "off"
        ],
        "off": [
            "off"
        ],
        "notAvailable": [
            "notAvailable"
        ]
    },
    "fonts": {
        "all": [
            "anaheim",
            "arizona",
            "brushScript",
            "connecticut",
            "fancy",
            "fullBlock",
            "maryland",
            "method",
            "miami",
            "oldEnglish",
            "plainBlock",
            "script",
            "proBlock",
            "monolith",
            "stinger",
            "rock",
            "anaheimNumber",
            "arizona47",
            "brushScriptNumber",
            "connecticutNumber",
            "fancy47",
            "fullBlockNumber",
            "marylandNumber",
            "methodNumber",
            "miami47",
            "plainBlockNumber",
            "proBlockNumber",
            "monolithNumber",
            "stingerNumber",
            "rockNumber"
        ],
        "teamName": [
            "anaheim",
            "arizona",
            "brushScript",
            "connecticut",
            "fancy",
            "fullBlock",
            "maryland",
            "method",
            "miami",
            "oldEnglish",
            "plainBlock",
            "script",
            "proBlock",
            "monolith",
            "stinger",
            "rock"
        ],
        "playerName": [
            "anaheim",
            "arizona",
            "brushScript",
            "connecticut",
            "fancy",
            "fullBlock",
            "maryland",
            "method",
            "miami",
            "oldEnglish",
            "plainBlock",
            "script",
            "proBlock",
            "monolith",
            "stinger",
            "rock"
        ],
        "watermarkText": [
            "anaheim",
            "arizona",
            "brushScript",
            "connecticut",
            "fancy",
            "fullBlock",
            "maryland",
            "method",
            "miami",
            "oldEnglish",
            "plainBlock",
            "script",
            "proBlock",
            "monolith",
            "stinger",
            "rock"
        ],
        "allText": [
            "anaheim",
            "arizona",
            "brushScript",
            "connecticut",
            "fancy",
            "fullBlock",
            "maryland",
            "method",
            "miami",
            "plainBlock",
            "script",
            "proBlock",
            "monolith",
            "stinger",
            "rock"
        ],
        "fasttrackText": [
            "connecticut",
            "fullBlock",
            "method",
            "miami",
            "script",
            "monolith",
            "stinger",
            "rock"
        ],
        "number": [
            "anaheimNumber",
            "arizona47",
            "brushScriptNumber",
            "connecticutNumber",
            "fancy47",
            "fullBlockNumber",
            "marylandNumber",
            "methodNumber",
            "miami47",
            "plainBlockNumber",
            "proBlockNumber",
            "monolithNumber",
            "stingerNumber",
            "rockNumber"
        ],
        "allNumber": [
            "anaheimNumber",
            "arizona47",
            "brushScriptNumber",
            "connecticutNumber",
            "fancy47",
            "fullBlockNumber",
            "marylandNumber",
            "methodNumber",
            "miami47",
            "plainBlockNumber",
            "monolithNumber",
            "stingerNumber",
            "rockNumber"
        ],
        "fasttrackNumber": [
            "connecticutNumber",
            "fullBlockNumber",
            "methodNumber",
            "miami47",
            "monolithNumber",
            "stingerNumber",
            "rockNumber"
        ]
    },
    "jerseys": {
        "all": [
            "WHBJ",
            "WRJ150",
            "CW2B",
            "CWJI",
            "CWMFF",
            "FXCFJ-SL",
            "FXCFJ-SS",
            "FTWVN1",
            "FTWVN2",
            "FTWVN3",
            "FTWVN4",
            "FTWVN5",
            "FTWVN6",
            "FTWVN7",
            "FTWCN1",
            "FTWCN2",
            "FTWCN3",
            "FTWCN4",
            "FTWCN5",
            "FTWCN6",
            "FTWCN7",
            "CW2BVF",
            "WARZVN",
            "WARZVNSL",
            "WBBVN",
            "WBBVNSL",
            "WCHIJ",
            "WCHIVNSL",
            "WCLNCHJ",
            "WDFVN",
            "WDFVNSL",
            "WHAVN",
            "WHAVNSL",
            "WHOUSJ",
            "WHOUSVNSL",
            "WHRDJ",
            "WHRDVNSL",
            "WKUJ",
            "WMBVN",
            "WMBVNSL",
            "WNYMJ",
            "WOAKJ",
            "WOAKVNSL",
            "WWINGVN",
            "WWINGVNSL",
            "CWJIVF",
            "CWMFFVF",
            "FXCFJSLVF",
            "FXCFJVF-SS",
            "WARZJ",
            "WARZFBSL",
            "WBBFB",
            "WBBFBSL",
            "WCHIFB",
            "WCHIFBSL",
            "WDFFB",
            "WDFFBSL",
            "WHAFB",
            "WHAFBSL",
            "WHOUSFB",
            "WHOUSFBSL",
            "WHRDFB",
            "WHRDFBSL",
            "WMBFB",
            "WMBFBSL",
            "WOAKFB",
            "WOAKFBSL",
            "WWINGFB",
            "WWINGFBSL",
            "WARZ2B",
            "WARZ2BSL",
            "WBB2B",
            "WBB2BSL",
            "WCHI2B",
            "WCHI2BSL",
            "WDF2B",
            "WDF2BSL",
            "WHA2B",
            "WHA2BSL",
            "WHOUS2B",
            "WHOUS2BSL",
            "WHRD2B",
            "WHRD2BSL",
            "WMB2B",
            "WMB2BSL",
            "WOAK2B",
            "WOAK2BSL",
            "WWING2B",
            "WWING2BSL"
        ],
        "default": [
            "WHBJ",
            "WRJ150",
            "FTWVN1",
            "FTWVN2",
            "FTWVN3",
            "FTWVN4",
            "FTWVN5",
            "FTWVN6",
            "FTWVN7",
            "FTWCN1",
            "FTWCN2",
            "FTWCN3",
            "FTWCN4",
            "FTWCN5",
            "FTWCN6",
            "FTWCN7",
            "CW2BVF",
            "WARZVN",
            "WARZVNSL",
            "WBBVN",
            "WBBVNSL",
            "WCHIJ",
            "WCHIVNSL",
            "WCLNCHJ",
            "WDFVN",
            "WDFVNSL",
            "WHAVN",
            "WHAVNSL",
            "WHOUSJ",
            "WHOUSVNSL",
            "WHRDJ",
            "WHRDVNSL",
            "WMBVN",
            "WMBVNSL",
            "WNYMJ",
            "WOAKJ",
            "WOAKVNSL",
            "WWINGVN",
            "WWINGVNSL",
            "CWJIVF",
            "CWMFFVF",
            "FXCFJSLVF",
            "FXCFJVF-SS",
            "WARZJ",
            "WARZFBSL",
            "WBBFB",
            "WBBFBSL",
            "WCHIFB",
            "WCHIFBSL",
            "WDFFB",
            "WDFFBSL",
            "WHAFB",
            "WHAFBSL",
            "WHOUSFB",
            "WHOUSFBSL",
            "WHRDFB",
            "WHRDFBSL",
            "WMBFB",
            "WMBFBSL",
            "WOAKFB",
            "WOAKFBSL",
            "WWINGFB",
            "WWINGFBSL",
            "WARZ2B",
            "WARZ2BSL",
            "WBB2B",
            "WBB2BSL",
            "WCHI2B",
            "WCHI2BSL",
            "WDF2B",
            "WDF2BSL",
            "WHA2B",
            "WHA2BSL",
            "WHOUS2B",
            "WHOUS2BSL",
            "WHRD2B",
            "WHRD2BSL",
            "WMB2B",
            "WMB2BSL",
            "WOAK2B",
            "WOAK2BSL",
            "WWING2B",
            "WWING2BSL"
        ],
        "bsn": [
            "WHBJ",
            "WRJ150",
            "FTWVN1",
            "FTWVN2",
            "FTWVN3",
            "FTWVN4",
            "FTWVN5",
            "FTWVN6",
            "FTWVN7",
            "FTWCN1",
            "FTWCN2",
            "FTWCN3",
            "FTWCN4",
            "FTWCN5",
            "FTWCN6",
            "FTWCN7",
            "CW2BVF",
            "WARZVN",
            "WARZVNSL",
            "WBBVN",
            "WBBVNSL",
            "WCHIJ",
            "WCHIVNSL",
            "WCLNCHJ",
            "WDFVN",
            "WDFVNSL",
            "WHAVN",
            "WHAVNSL",
            "WHOUSJ",
            "WHOUSVNSL",
            "WHRDJ",
            "WHRDVNSL",
            "WKUJ",
            "WMBVN",
            "WMBVNSL",
            "WNYMJ",
            "WOAKJ",
            "WOAKVNSL",
            "WWINGVN",
            "WWINGVNSL",
            "CWJIVF",
            "CWMFFVF",
            "FXCFJSLVF",
            "FXCFJVF-SS",
            "WARZJ",
            "WARZFBSL",
            "WBBFB",
            "WBBFBSL",
            "WCHIFB",
            "WCHIFBSL",
            "WDFFB",
            "WDFFBSL",
            "WHAFB",
            "WHAFBSL",
            "WHOUSFB",
            "WHOUSFBSL",
            "WHRDFB",
            "WHRDFBSL",
            "WMBFB",
            "WMBFBSL",
            "WOAKFB",
            "WOAKFBSL",
            "WWINGFB",
            "WWINGFBSL",
            "WARZ2B",
            "WARZ2BSL",
            "WBB2B",
            "WBB2BSL",
            "WCHI2B",
            "WCHI2BSL",
            "WDF2B",
            "WDF2BSL",
            "WHA2B",
            "WHA2BSL",
            "WHOUS2B",
            "WHOUS2BSL",
            "WHRD2B",
            "WHRD2BSL",
            "WMB2B",
            "WMB2BSL",
            "WOAK2B",
            "WOAK2BSL",
            "WWING2B",
            "WWING2BSL"
        ]
    },
    "pants": {
        "all": [
            "WRB150",
            "WLNCH",
            "WBDP",
            "WYP",
            "FXCIP",
            "FXCLP",
            "FXCLP2",
            "FXCLP3",
            "FXCIPVF",
            "FXCLPVF",
            "FXCLP2VF",
            "FXCLP3VF"
        ],
        "default": [
            "WRB150",
            "WLNCH",
            "WYP",
            "FXCIPVF",
            "FXCLPVF",
            "FXCLP2VF",
            "FXCLP3VF"
        ],
        "bsn": [
            "WRB150",
            "WLNCH",
            "WYP",
            "FXCIPVF",
            "FXCLPVF",
            "FXCLP2VF",
            "FXCLP3VF"
        ]
    },
    "garments": {
        "all": [
            "WHBJ",
            "WRJ150",
            "CW2B",
            "CWJI",
            "CWMFF",
            "FXCFJ-SL",
            "FXCFJ-SS",
            "FTWVN1",
            "FTWVN2",
            "FTWVN3",
            "FTWVN4",
            "FTWVN5",
            "FTWVN6",
            "FTWVN7",
            "FTWCN1",
            "FTWCN2",
            "FTWCN3",
            "FTWCN4",
            "FTWCN5",
            "FTWCN6",
            "FTWCN7",
            "CW2BVF",
            "WARZVN",
            "WARZVNSL",
            "WBBVN",
            "WBBVNSL",
            "WCHIJ",
            "WCHIVNSL",
            "WCLNCHJ",
            "WDFVN",
            "WDFVNSL",
            "WHAVN",
            "WHAVNSL",
            "WHOUSJ",
            "WHOUSVNSL",
            "WHRDJ",
            "WHRDVNSL",
            "WKUJ",
            "WMBVN",
            "WMBVNSL",
            "WNYMJ",
            "WOAKJ",
            "WOAKVNSL",
            "WWINGVN",
            "WWINGVNSL",
            "CWJIVF",
            "CWMFFVF",
            "FXCFJSLVF",
            "FXCFJVF-SS",
            "WARZJ",
            "WARZFBSL",
            "WBBFB",
            "WBBFBSL",
            "WCHIFB",
            "WCHIFBSL",
            "WDFFB",
            "WDFFBSL",
            "WHAFB",
            "WHAFBSL",
            "WHOUSFB",
            "WHOUSFBSL",
            "WHRDFB",
            "WHRDFBSL",
            "WMBFB",
            "WMBFBSL",
            "WOAKFB",
            "WOAKFBSL",
            "WWINGFB",
            "WWINGFBSL",
            "WARZ2B",
            "WARZ2BSL",
            "WBB2B",
            "WBB2BSL",
            "WCHI2B",
            "WCHI2BSL",
            "WDF2B",
            "WDF2BSL",
            "WHA2B",
            "WHA2BSL",
            "WHOUS2B",
            "WHOUS2BSL",
            "WHRD2B",
            "WHRD2BSL",
            "WMB2B",
            "WMB2BSL",
            "WOAK2B",
            "WOAK2BSL",
            "WWING2B",
            "WWING2BSL",
            "WRB150",
            "WLNCH",
            "WBDP",
            "WYP",
            "FXCIP",
            "FXCLP",
            "FXCLP2",
            "FXCLP3",
            "FXCIPVF",
            "FXCLPVF",
            "FXCLP2VF",
            "FXCLP3VF"
        ],
        "default": [
            "WHBJ",
            "WRJ150",
            "FTWVN1",
            "FTWVN2",
            "FTWVN3",
            "FTWVN4",
            "FTWVN5",
            "FTWVN6",
            "FTWVN7",
            "FTWCN1",
            "FTWCN2",
            "FTWCN3",
            "FTWCN4",
            "FTWCN5",
            "FTWCN6",
            "FTWCN7",
            "CW2BVF",
            "WARZVN",
            "WARZVNSL",
            "WBBVN",
            "WBBVNSL",
            "WCHIJ",
            "WCHIVNSL",
            "WCLNCHJ",
            "WDFVN",
            "WDFVNSL",
            "WHAVN",
            "WHAVNSL",
            "WHOUSJ",
            "WHOUSVNSL",
            "WHRDJ",
            "WHRDVNSL",
            "WMBVN",
            "WMBVNSL",
            "WNYMJ",
            "WOAKJ",
            "WOAKVNSL",
            "WWINGVN",
            "WWINGVNSL",
            "CWJIVF",
            "CWMFFVF",
            "FXCFJSLVF",
            "FXCFJVF-SS",
            "WARZJ",
            "WARZFBSL",
            "WBBFB",
            "WBBFBSL",
            "WCHIFB",
            "WCHIFBSL",
            "WDFFB",
            "WDFFBSL",
            "WHAFB",
            "WHAFBSL",
            "WHOUSFB",
            "WHOUSFBSL",
            "WHRDFB",
            "WHRDFBSL",
            "WMBFB",
            "WMBFBSL",
            "WOAKFB",
            "WOAKFBSL",
            "WWINGFB",
            "WWINGFBSL",
            "WARZ2B",
            "WARZ2BSL",
            "WBB2B",
            "WBB2BSL",
            "WCHI2B",
            "WCHI2BSL",
            "WDF2B",
            "WDF2BSL",
            "WHA2B",
            "WHA2BSL",
            "WHOUS2B",
            "WHOUS2BSL",
            "WHRD2B",
            "WHRD2BSL",
            "WMB2B",
            "WMB2BSL",
            "WOAK2B",
            "WOAK2BSL",
            "WWING2B",
            "WWING2BSL",
            "WRB150",
            "WLNCH",
            "WYP",
            "FXCIPVF",
            "FXCLPVF",
            "FXCLP2VF",
            "FXCLP3VF"
        ],
        "bsn": [
            "WHBJ",
            "WRJ150",
            "FTWVN1",
            "FTWVN2",
            "FTWVN3",
            "FTWVN4",
            "FTWVN5",
            "FTWVN6",
            "FTWVN7",
            "FTWCN1",
            "FTWCN2",
            "FTWCN3",
            "FTWCN4",
            "FTWCN5",
            "FTWCN6",
            "FTWCN7",
            "CW2BVF",
            "WARZVN",
            "WARZVNSL",
            "WBBVN",
            "WBBVNSL",
            "WCHIJ",
            "WCHIVNSL",
            "WCLNCHJ",
            "WDFVN",
            "WDFVNSL",
            "WHAVN",
            "WHAVNSL",
            "WHOUSJ",
            "WHOUSVNSL",
            "WHRDJ",
            "WHRDVNSL",
            "WKUJ",
            "WMBVN",
            "WMBVNSL",
            "WNYMJ",
            "WOAKJ",
            "WOAKVNSL",
            "WWINGVN",
            "WWINGVNSL",
            "CWJIVF",
            "CWMFFVF",
            "FXCFJSLVF",
            "FXCFJVF-SS",
            "WARZJ",
            "WARZFBSL",
            "WBBFB",
            "WBBFBSL",
            "WCHIFB",
            "WCHIFBSL",
            "WDFFB",
            "WDFFBSL",
            "WHAFB",
            "WHAFBSL",
            "WHOUSFB",
            "WHOUSFBSL",
            "WHRDFB",
            "WHRDFBSL",
            "WMBFB",
            "WMBFBSL",
            "WOAKFB",
            "WOAKFBSL",
            "WWINGFB",
            "WWINGFBSL",
            "WARZ2B",
            "WARZ2BSL",
            "WBB2B",
            "WBB2BSL",
            "WCHI2B",
            "WCHI2BSL",
            "WDF2B",
            "WDF2BSL",
            "WHA2B",
            "WHA2BSL",
            "WHOUS2B",
            "WHOUS2BSL",
            "WHRD2B",
            "WHRD2BSL",
            "WMB2B",
            "WMB2BSL",
            "WOAK2B",
            "WOAK2BSL",
            "WWING2B",
            "WWING2BSL",
            "WRB150",
            "WLNCH",
            "WYP",
            "FXCIPVF",
            "FXCLPVF",
            "FXCLP2VF",
            "FXCLP3VF"
        ]
    },
    "colors": {
        "braid": [
            "GR",
            "B",
            "W",
            "SG",
            "BG",
            "DB",
            "DM",
            "HP",
            "LP",
            "C",
            "S",
            "TX",
            "BO",
            "TO",
            "LG",
            "LT",
            "K",
            "DG",
            "AQ",
            "MB",
            "CB",
            "AF",
            "R",
            "N",
            "PU",
            "OG",
            "VG"
        ],
        "braidOptional": [
            "none",
            "GR",
            "B",
            "W",
            "SG",
            "BG",
            "DB",
            "DM",
            "HP",
            "LP",
            "C",
            "S",
            "TX",
            "BO",
            "TO",
            "LG",
            "LT",
            "K",
            "DG",
            "AQ",
            "MB",
            "CB",
            "AF",
            "R",
            "N",
            "PU",
            "OG",
            "VG"
        ],
        "customProJerseys": [
            "B",
            "W",
            "GR",
            "BG",
            "P",
            "N"
        ],
        "customProJerseysOptional": [
            "none",
            "B",
            "W",
            "GR",
            "BG",
            "P",
            "N"
        ],
        "customCollegiateJerseys": [
            "B",
            "W",
            "GR",
            "BG",
            "S",
            "P",
            "CB",
            "R",
            "N"
        ],
        "customCollegiateJerseysOptional": [
            "none",
            "B",
            "W",
            "GR",
            "BG",
            "S",
            "P",
            "CB",
            "R",
            "N"
        ],
        "customTravelballJerseys": [
            "W"
        ],
        "customTravelballJerseysOptional": [
            "none",
            "W"
        ],
        "sublimatedProJerseys": [
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "sublimatedProJerseysOptional": [
            "none",
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "sublimatedCollegiateJerseys": [
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "sublimatedCollegiateJerseysOptional": [
            "none",
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "sublimatedTravelballJerseys": [
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "sublimatedTravelballJerseysOptional": [
            "none",
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "fasttrackSublimatedJerseys": [
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "fasttrackSublimatedJerseysOptional": [
            "none",
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "customProPants": [
            "B",
            "W",
            "GR",
            "BG",
            "P",
            "N"
        ],
        "customProPantsOptional": [
            "none",
            "B",
            "W",
            "GR",
            "BG",
            "P",
            "N"
        ],
        "customCollegiatePants": [
            "B",
            "W",
            "GR",
            "BG",
            "S",
            "P",
            "CB",
            "R",
            "N"
        ],
        "customCollegiatePantsOptional": [
            "none",
            "B",
            "W",
            "GR",
            "BG",
            "S",
            "P",
            "CB",
            "R",
            "N"
        ],
        "sublimatedProPants": [
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "sublimatedProPantsOptional": [
            "none",
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "sublimatedCollegiatePants": [
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "sublimatedCollegiatePantsOptional": [
            "none",
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "sublimatedProShorts": [
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "sublimatedProShortsOptional": [
            "none",
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "sublimatedCollegiateShorts": [
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "sublimatedCollegiateShortsOptional": [
            "none",
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "sublimatedTravelballShorts": [
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "sublimatedTravelballShortsOptional": [
            "none",
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "WB150": [
            "B",
            "N",
            "W",
            "BG"
        ],
        "WB150Optional": [
            "none",
            "B",
            "N",
            "W",
            "BG"
        ],
        "WLNCH": [
            "B",
            "N",
            "W",
            "BG",
            "GR",
            "R",
            "S"
        ],
        "WLNCHOptional": [
            "none",
            "B",
            "N",
            "W",
            "BG",
            "GR",
            "R",
            "S"
        ],
        "WBDP": [
            "B",
            "N",
            "W",
            "BG"
        ],
        "WBDPOptional": [
            "none",
            "B",
            "N",
            "W",
            "BG"
        ],
        "WJ150": [
            "B",
            "N",
            "W",
            "BG"
        ],
        "WJ150Optional": [
            "none",
            "B",
            "N",
            "W",
            "BG"
        ],
        "WMFF": [
            "B",
            "N",
            "R",
            "S",
            "W"
        ],
        "WMFFOptional": [
            "none",
            "B",
            "N",
            "R",
            "S",
            "W"
        ],
        "W2BM": [
            "B",
            "DG",
            "N",
            "R",
            "PU",
            "S"
        ],
        "W2BMOptional": [
            "none",
            "B",
            "DG",
            "N",
            "R",
            "PU",
            "S"
        ],
        "knit": [
            "B",
            "W",
            "S",
            "GR",
            "BG",
            "DB",
            "DM",
            "MA",
            "HP",
            "LP",
            "C",
            "SG",
            "TX",
            "BO",
            "TN",
            "LG",
            "LT",
            "OG",
            "VG",
            "K",
            "DG",
            "AQ",
            "MB",
            "CB",
            "AF",
            "R",
            "N",
            "PU"
        ],
        "knitOptional": [
            "none",
            "B",
            "W",
            "S",
            "GR",
            "BG",
            "DB",
            "DM",
            "MA",
            "HP",
            "LP",
            "C",
            "SG",
            "TX",
            "BO",
            "TN",
            "LG",
            "LT",
            "OG",
            "VG",
            "K",
            "DG",
            "AQ",
            "MB",
            "CB",
            "AF",
            "R",
            "N",
            "PU"
        ],
        "ribKnit": [
            "GR",
            "B",
            "W",
            "SG",
            "BG",
            "DB",
            "DM",
            "HP",
            "LP",
            "C",
            "S",
            "TX",
            "BO",
            "TO",
            "LG",
            "LT",
            "K",
            "DG",
            "AQ",
            "MB",
            "CB",
            "AF",
            "R",
            "N",
            "PU",
            "OG",
            "VG"
        ],
        "ribKnitOptional": [
            "none",
            "GR",
            "B",
            "W",
            "SG",
            "BG",
            "DB",
            "DM",
            "HP",
            "LP",
            "C",
            "S",
            "TX",
            "BO",
            "TO",
            "LG",
            "LT",
            "K",
            "DG",
            "AQ",
            "MB",
            "CB",
            "AF",
            "R",
            "N",
            "PU",
            "OG",
            "VG"
        ],
        "sublimated": [
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "sublimatedOptional": [
            "none",
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "KG",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "embroidery": [
            "B",
            "W",
            "SG",
            "DB",
            "DM",
            "HP",
            "LP",
            "C",
            "S",
            "BO",
            "TO",
            "LG",
            "LT",
            "OG",
            "VG",
            "K",
            "DG",
            "MB",
            "CB",
            "R",
            "N",
            "PU"
        ],
        "embroideryOptional": [
            "none",
            "B",
            "W",
            "SG",
            "DB",
            "DM",
            "HP",
            "LP",
            "C",
            "S",
            "BO",
            "TO",
            "LG",
            "LT",
            "OG",
            "VG",
            "K",
            "DG",
            "MB",
            "CB",
            "R",
            "N",
            "PU"
        ],
        "RVT": [
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "K",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "REF"
        ],
        "RVTOptional": [
            "none",
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "K",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "REF"
        ],
        "button": [
            "B",
            "N",
            "R",
            "S",
            "SG",
            "VG",
            "BG",
            "BO",
            "CMG",
            "CB",
            "DG",
            "KG",
            "LG",
            "MA",
            "P",
            "PU",
            "W"
        ],
        "buttonOptional": [
            "none",
            "B",
            "N",
            "R",
            "S",
            "SG",
            "VG",
            "BG",
            "BO",
            "CMG",
            "CB",
            "DG",
            "KG",
            "LG",
            "MA",
            "P",
            "PU",
            "W"
        ],
        "reflective": [
            "REF"
        ],
        "reflectiveOptional": [
            "none",
            "REF"
        ],
        "onlyWhite": [
            "W"
        ],
        "onlyWhiteOptional": [
            "none",
            "W"
        ],
        "onlyBlueGray": [
            "BG"
        ],
        "onlyBlueGrayOptional": [
            "none",
            "BG"
        ],
        "WHBJ": [
            "B",
            "C",
            "DG",
            "N",
            "R",
            "S"
        ],
        "WHBJOptional": [
            "none",
            "B",
            "C",
            "DG",
            "N",
            "R",
            "S"
        ],
        "WYP": [
            "B",
            "GR",
            "N",
            "W"
        ],
        "WYPOptional": [
            "none",
            "B",
            "GR",
            "N",
            "W"
        ]
    },
    "opPatterns": {
        "all": [
            "none",
            "1color",
            "2color",
            "3color",
            "0125_1",
            "0375_1",
            "0375_3",
            "0375_6",
            "0500_1",
            "0500_2",
            "0500_3",
            "0500_6",
            "0750_1",
            "0750_3",
            "0750_6",
            "1000_1",
            "1000_2",
            "1000_3",
            "1000_6",
            "1500_1",
            "1500_2",
            "1500_3",
            "1500_6"
        ],
        "jersey": [
            "none",
            "1color",
            "2color",
            "3color"
        ],
        "op4": [
            "none",
            "1color",
            "2color",
            "3color"
        ],
        "op15": [
            "none",
            "1color",
            "2color",
            "3color"
        ]
    },
    "ribKnitPatterns": {
        "all": [
            "none",
            "1inch1color",
            "1inch3color",
            "2inch1color",
            "2inch3color"
        ],
        "collar": [
            "none",
            "1inch1color",
            "1inch3color"
        ],
        "cuff": [
            "none",
            "1inch1color",
            "1inch3color",
            "2inch1color",
            "2inch3color"
        ],
        "RIBWST": [
            "none",
            "2inch1color",
            "2inch3color"
        ]
    },
    "sleeveStripes": {
        "all": []
    },
    "designPatterns": {
        "all": [
            "solidColor",
            "argyle",
            "autoCamo",
            "autoDigitalCamo",
            "bustedDots",
            "bustedHoneycomb",
            "camo",
            "carbonBlock",
            "carbonFiber",
            "check",
            "check5",
            "digitalCamo",
            "digitalWave",
            "electric",
            "fade",
            "flannel",
            "geo",
            "gradientDots",
            "gradientPlaid",
            "hound",
            "plaid",
            "plated",
            "proStripe",
            "scratch",
            "shutter",
            "splat",
            "split",
            "tieDye",
            "crackle",
            "triadStripe",
            "heather",
            "zebraDots",
            "houndsTooth",
            "rvfj4",
            "rvfj7",
            "rvfj8",
            "rvfjfg",
            "rvfjmz",
            "ohio",
            "streak",
            "predator",
            "bearcat",
            "wingback",
            "aggie",
            "flag",
            "digitalWave-element",
            "tigerStripe",
            "ribKnit",
            "ribKnitPattern01",
            "ribKnitPattern02",
            "ribKnitPattern03",
            "ribKnitPattern06",
            "pattern01",
            "pattern02",
            "pattern03",
            "pattern06"
        ],
        "solidColors": [
            "solidColor"
        ],
        "standard": [
            "solidColor",
            "autoCamo",
            "autoDigitalCamo",
            "bustedDots",
            "bustedHoneycomb",
            "camo",
            "check",
            "digitalCamo",
            "electric",
            "fade",
            "flannel",
            "gradientDots",
            "plated",
            "proStripe",
            "scratch",
            "shutter",
            "splat",
            "split",
            "crackle",
            "triadStripe",
            "heather",
            "zebraDots",
            "houndsTooth"
        ],
        "noFade": [
            "solidColor",
            "autoCamo",
            "autoDigitalCamo",
            "bustedDots",
            "bustedHoneycomb",
            "camo",
            "check",
            "digitalCamo",
            "electric",
            "flannel",
            "plated",
            "proStripe",
            "scratch",
            "shutter",
            "splat",
            "split",
            "crackle",
            "triadStripe",
            "heather",
            "zebraDots",
            "houndsTooth"
        ],
        "jerseyBody": [
            "solidColor",
            "autoCamo",
            "autoDigitalCamo",
            "camo",
            "digitalCamo",
            "electric",
            "fade",
            "flannel",
            "proStripe",
            "splat",
            "crackle",
            "triadStripe",
            "heather",
            "zebraDots",
            "houndsTooth"
        ],
        "jerseyBodyNoFade": [
            "solidColor",
            "autoCamo",
            "autoDigitalCamo",
            "camo",
            "digitalCamo",
            "electric",
            "flannel",
            "proStripe",
            "splat",
            "crackle",
            "triadStripe",
            "heather",
            "zebraDots",
            "houndsTooth"
        ],
        "pantBody": [
            "solidColor",
            "flannel",
            "proStripe",
            "triadStripe",
            "heather"
        ],
        "patternAndStandard": [
            "pattern01",
            "pattern02",
            "pattern03",
            "pattern06",
            "solidColor",
            "autoCamo",
            "autoDigitalCamo",
            "bustedDots",
            "bustedHoneycomb",
            "camo",
            "check",
            "digitalCamo",
            "electric",
            "fade",
            "flannel",
            "gradientDots",
            "plated",
            "proStripe",
            "scratch",
            "shutter",
            "splat",
            "split",
            "crackle",
            "triadStripe",
            "heather",
            "zebraDots",
            "houndsTooth"
        ],
        "pattern": [
            "pattern01",
            "pattern03",
            "pattern06"
        ]
    },
    "logos": {
        "all": [
            "USFLAG",
            "7ON7",
            "BASEBALL1",
            "BASEBALL2",
            "BASEBALL3",
            "BASEBALL4",
            "BASEBALL5",
            "BASEBALL6",
            "BASKETBALL1",
            "BASKETBALL2",
            "BASKETBALL3",
            "BASKETBALL4",
            "BEAR1",
            "BEAR2",
            "BEAR3",
            "BEAR4",
            "BEAR5",
            "BEAR6",
            "BIRD1",
            "BIRD2",
            "BIRD3",
            "BIRD4",
            "BIRD5",
            "BIRD6",
            "BIRD7",
            "BIRD8",
            "BIRD9",
            "BIRD10",
            "BIRD11",
            "BIRD12",
            "BIRD13",
            "BIRD14",
            "BIRD15",
            "BIRD16",
            "BIRD17",
            "BIRD18",
            "BIRD19",
            "BIRD20",
            "BIRD21",
            "BIRD22",
            "BIRD23",
            "BIRD24",
            "BIRD25",
            "BIRD26",
            "BIRD27",
            "BIRD28",
            "BIRD29",
            "BIRD30",
            "BRAVE1",
            "BRAVE2",
            "BUCCANEER",
            "BUFFALO",
            "BULLDOG1",
            "BULLDOG2",
            "BULLDOG3",
            "BULLDOG4",
            "BULLDOG5",
            "BULLDOG6",
            "BULLDOG7",
            "BULLDOG8",
            "BULLDOG9",
            "BULLDOG10",
            "CARDINAL1",
            "CARDINAL2",
            "CAT1",
            "CAT2",
            "CAT3",
            "CAT4",
            "CAT5",
            "CAT6",
            "CAT7",
            "CAT8",
            "CAT9",
            "CAT10",
            "CAT11",
            "CAT12",
            "CAT13",
            "CAT14",
            "CAT15",
            "CAT16",
            "CAT17",
            "CAT18",
            "CAT19",
            "CAVALIER1",
            "CAVALIER2",
            "CAVALIER3",
            "CHIEF",
            "COBRA",
            "COMICTROJAN",
            "COUGAR",
            "DEVIL",
            "DEVIL2",
            "DEVIL3",
            "DEVIL4",
            "DEVIL5",
            "DRAGON",
            "DRAGONH13",
            "DRAGONI13",
            "EAGLE1",
            "EAGLE2",
            "EAGLE3",
            "EAGLE4",
            "EAGLE5",
            "FOOTBALL1",
            "FOOTBALL2",
            "FOOTBALL3",
            "FOOTBALL4",
            "FOOTBALL5",
            "HELMET",
            "HORSE1",
            "HORSE10",
            "HORSE2",
            "HORSE3",
            "HORSE4",
            "HORSE5",
            "HORSE6",
            "HORSE7",
            "HORSE8",
            "HORSE9",
            "HUSKYF1",
            "INDIAN1",
            "INDIAN2",
            "INDIAN3",
            "INDIAN4",
            "INDIAN5",
            "INDIAN6",
            "INDIAN7",
            "INDIAN8",
            "INDIAN9",
            "INDIAN10",
            "INDIAN11",
            "INDIAN12",
            "INDIAN13",
            "INDIAN14",
            "INDIAN15",
            "INSECT1",
            "INSECT2",
            "INSECT3",
            "INSECT4",
            "INSECT5",
            "KNIGHT1",
            "KNIGHT2",
            "KNIGHT3",
            "KNIGHT4",
            "KNIGHT5",
            "KNIGHT6",
            "KNIGHT7",
            "KNIGHT8",
            "KNIGHT9",
            "KNIGHT10",
            "LIGHTNING1",
            "LIGHTNING2",
            "LIGHTNING3",
            "LIGHTNING4",
            "LION1",
            "LION2",
            "LION3",
            "LION4",
            "LION5",
            "LION6",
            "LION7",
            "LION8",
            "LONGHORNS",
            "MARLIN",
            "MISC1",
            "MISC2",
            "MISC3",
            "MISC4",
            "MISC5",
            "MISC6",
            "MISC7",
            "MISC8",
            "MISC9",
            "MISC10",
            "MISC11",
            "MISC12",
            "MISC13",
            "MISC14",
            "MISC15",
            "MISC16",
            "MISC17",
            "MISC18",
            "MISC19",
            "MISC20",
            "MISC21",
            "MISC22",
            "MISC23",
            "MUSTANG1",
            "MUSTANG2",
            "PANTHER1",
            "PANTHER2",
            "PAW1",
            "PAW2",
            "PAW3",
            "PAW4",
            "PAW5",
            "PAW6",
            "PAW7",
            "PAW8",
            "PAW9",
            "PAW10",
            "PAW11",
            "PAW12",
            "PAW13",
            "PAW14",
            "PAW15",
            "PAW16",
            "PAW17",
            "PAW18",
            "PIRATE1",
            "PIRATE2",
            "PIRATE3",
            "PIRATE4",
            "PIRATE5",
            "PIRATE6",
            "PIRATE7",
            "PIRATE8",
            "PIRATE9",
            "PIRATE10",
            "PIRATE11",
            "PIRATE12",
            "PIRATE13",
            "RAM1",
            "RAM2",
            "REBEL",
            "SHAMROCK1",
            "SHAMROCK2",
            "SHARK1",
            "SNAKE1",
            "SNAKE2",
            "SNAKE3",
            "SPARTAN1",
            "SPARTAN2",
            "SPARTAN3",
            "SPARTAN4",
            "SPARTAN5",
            "SPARTAN6",
            "SPARTAN7",
            "SPARTAN8",
            "SPARTAN9",
            "SPARTAN10",
            "SPARTAN11",
            "SPARTAN12",
            "SPARTAN13",
            "SPARTAN14",
            "STAR1",
            "STAR2",
            "STAR3",
            "STAR4",
            "STAR5",
            "TIGER1",
            "TIGER2",
            "TIGER3",
            "VIKING1",
            "VIKING2",
            "VIKING3",
            "VIKING4",
            "VIKING5",
            "VIKING6",
            "WILDCAT1",
            "WILDCAT2",
            "WILDCAT3",
            "WOLF",
            "WOLF1",
            "WOLF2",
            "WOLF3",
            "WOLF4",
            "WOLF5"
        ],
        "watermark": [
            "7ON7",
            "BASEBALL4",
            "BASKETBALL3",
            "BEAR2",
            "BIRD15",
            "BIRD16",
            "BIRD24",
            "BIRD25",
            "BIRD30",
            "CAT9",
            "DRAGONH13",
            "DRAGONI13",
            "HORSE7",
            "HORSE8",
            "HORSE9",
            "INDIAN3",
            "INDIAN4",
            "INDIAN6",
            "INDIAN7",
            "INSECT4",
            "KNIGHT3",
            "KNIGHT5",
            "MISC2",
            "MISC14",
            "MISC16",
            "MISC17",
            "MISC20",
            "MISC22",
            "MISC23",
            "PAW1",
            "PAW3",
            "PAW4",
            "PAW5",
            "PAW7",
            "PAW8",
            "PAW9",
            "PAW10",
            "PAW11",
            "PAW13",
            "PAW14",
            "PAW15",
            "PAW16",
            "PIRATE11",
            "PIRATE12",
            "SHAMROCK1",
            "SHAMROCK2",
            "SNAKE1",
            "SPARTAN9",
            "SPARTAN10",
            "SPARTAN11",
            "SPARTAN14",
            "STAR1",
            "STAR4",
            "STAR5",
            "WOLF2"
        ]
    },
    "tabs": {
        "all": [
            "select",
            "fabricColors",
            "options",
            "decorate"
        ]
    },
    "clothingCollections": {
        "all": [
            "stockJersey",
            "customJerseys",
            "fasttrackSublimatedJerseys",
            "sublimatedTwoButtonJersey",
            "sublimatedVNeckJersey",
            "sublimatedFullButtonJersey",
            "stockPants",
            "customPants",
            "sublimatedPants"
        ]
    },
    "clothingTypes": {
        "all": [
            "jersey",
            "pant"
        ]
    },
    "areaFillExceptions": {
        "all": [
            "FTWVN5",
            "FTWVN6",
            "FTWVN7",
            "FTWCN5",
            "FTWCN6",
            "FTWCN7",
            "WNYMJ",
            "WHBJ"
        ]
    },
    "fabrics": {
        "all": [
            "braid",
            "customProJerseys",
            "customCollegiateJerseys",
            "customTravelballJerseys",
            "sublimatedProJerseys",
            "sublimatedCollegiateJerseys",
            "sublimatedTravelballJerseys",
            "fasttrackSublimatedJerseys",
            "customProPants",
            "customCollegiatePants",
            "sublimatedProPants",
            "sublimatedCollegiatePants",
            "sublimatedProShorts",
            "sublimatedCollegiateShorts",
            "sublimatedTravelballShorts",
            "WB150",
            "WLNCH",
            "WBDP",
            "WJ150",
            "WMFF",
            "W2BM",
            "knit",
            "ribKnit",
            "sublimated",
            "embroidery",
            "RVT",
            "button",
            "reflective",
            "onlyWhite",
            "onlyBlueGray",
            "WHBJ",
            "WYP"
        ]
    },
    "decoSets": {
        "all": [
            "vt",
            "rvt",
            "fullSublimation",
            "fullSublimationCHI",
            "fasttrack",
            "tg",
            "tgYouth",
            "pantDeco",
            "none"
        ]
    },
    "decos": {
        "all": [
            "jersey",
            "pant"
        ]
    },
    "decoSizes": {
        "all": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "8",
            "10",
            "12",
            "0.125",
            "0.375",
            "0.75",
            "1.5",
            "2.5",
            "3.5"
        ]
    },
    "decoOptions": {
        "all": [
            "OP1",
            "OP2",
            "OP3",
            "OP4",
            "OP4_2",
            "OP15",
            "RIBWST",
            "ribKnitCollar",
            "ribKnitCuff",
            "buttonColor",
            "logoColor"
        ]
    },
    "decoPlacements": {
        "all": [
            "1",
            "2",
            "3",
            "4",
            "6",
            "14",
            "30",
            "none",
            "17L",
            "17R",
            "5B",
            "30B",
            "27L",
            "27R"
        ]
    },
    "layouts": {
        "all": [
            "straight",
            "angled",
            "verticalArch",
            "slanted",
            "bridgedArch"
        ]
    },
    "cutTypes": {
        "all": [
            "adult",
            "youth"
        ]
    },
    "sizes": {
        "all": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "8",
            "10",
            "12",
            "0.125",
            "0.375",
            "0.75",
            "1.5",
            "2.5",
            "3.5"
        ]
    }
}