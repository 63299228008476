module.exports={
    "1": {
        "name": "Upper Right Chest",
        "abbr": "1",
        "code": "1",
        "width": 4,
        "height": 4,
        "conflictsWith": [
            "2"
        ]
    },
    "2": {
        "name": "Upper Center Chest",
        "abbr": "2",
        "code": "2",
        "width": 15,
        "height": 4,
        "conflictsWith": [
            "1",
            "3"
        ]
    },
    "3": {
        "name": "Upper Left Chest",
        "abbr": "3",
        "code": "3",
        "width": 4,
        "height": 4,
        "conflictsWith": [
            "2"
        ]
    },
    "4": {
        "name": "Middle Right",
        "abbr": "4",
        "code": "4",
        "width": 4,
        "height": 4,
        "conflictsWith": []
    },
    "6": {
        "name": "Middle Left",
        "abbr": "6",
        "code": "6",
        "width": 4,
        "height": 4,
        "conflictsWith": []
    },
    "14": {
        "name": "Upper Back",
        "abbr": "14",
        "code": "14",
        "width": 10,
        "height": 4,
        "conflictsWith": []
    },
    "30": {
        "name": "Back Number",
        "abbr": "30",
        "code": "30",
        "width": 10,
        "height": 8,
        "conflictsWith": []
    },
    "none": {
        "name": "None",
        "abbr": "NONE",
        "code": "none",
        "width": null,
        "height": null,
        "conflictsWith": []
    },
    "17L": {
        "name": "Left Sleeve",
        "abbr": "17L",
        "code": "17L",
        "width": 4,
        "height": 4,
        "conflictsWith": []
    },
    "17R": {
        "name": "Right Sleeve",
        "abbr": "17R",
        "code": "17R",
        "width": 4,
        "height": 4,
        "conflictsWith": []
    },
    "5B": {
        "name": "Watermark (front)",
        "abbr": "5B",
        "code": "5B",
        "width": 12,
        "height": 12,
        "conflictsWith": []
    },
    "30B": {
        "name": "Watermark (back)",
        "abbr": "30B",
        "code": "30B",
        "width": 12,
        "height": 12,
        "conflictsWith": []
    },
    "27L": {
        "name": "Outer Left Leg",
        "abbr": "27L",
        "code": "27L",
        "width": 4,
        "height": 4,
        "conflictsWith": []
    },
    "27R": {
        "name": "Outer Right Leg",
        "abbr": "27R",
        "code": "27R",
        "width": 4,
        "height": 4,
        "conflictsWith": []
    }
}