module.exports={
    "FTWVN5": [
        {
            "code": "FTWVN5",
            "area": "area3",
            "colorSet": null,
            "patterns": [
                "fade"
            ],
            "patternSet": null
        },
        {
            "code": "FTWVN5",
            "area": "area4",
            "colorSet": null,
            "patterns": [
                "fade"
            ],
            "patternSet": null
        }
    ],
    "FTWVN6": [
        {
            "code": "FTWVN6",
            "area": "area1",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "digitalCamo"
            ],
            "patternSet": null
        },
        {
            "code": "FTWVN6",
            "area": "area2",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "digitalCamo"
            ],
            "patternSet": null
        },
        {
            "code": "FTWVN6",
            "area": "area3",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "digitalCamo"
            ],
            "patternSet": null
        },
        {
            "code": "FTWVN6",
            "area": "area4",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "digitalCamo"
            ],
            "patternSet": null
        },
        {
            "code": "FTWVN6",
            "area": "area5",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "digitalCamo"
            ],
            "patternSet": null
        }
    ],
    "FTWVN7": [
        {
            "code": "FTWVN7",
            "area": "area1",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "heather"
            ],
            "patternSet": null
        },
        {
            "code": "FTWVN7",
            "area": "area2",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "heather"
            ],
            "patternSet": null
        },
        {
            "code": "FTWVN7",
            "area": "area3",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "heather"
            ],
            "patternSet": null
        },
        {
            "code": "FTWVN7",
            "area": "area4",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "heather"
            ],
            "patternSet": null
        },
        {
            "code": "FTWVN7",
            "area": "area5",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "heather"
            ],
            "patternSet": null
        }
    ],
    "FTWCN5": [
        {
            "code": "FTWCN5",
            "area": "area3",
            "colorSet": null,
            "patterns": [
                "fade"
            ],
            "patternSet": null
        },
        {
            "code": "FTWCN5",
            "area": "area4",
            "colorSet": null,
            "patterns": [
                "fade"
            ],
            "patternSet": null
        }
    ],
    "FTWCN6": [
        {
            "code": "FTWCN6",
            "area": "area1",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "digitalCamo"
            ],
            "patternSet": null
        },
        {
            "code": "FTWCN6",
            "area": "area2",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "digitalCamo"
            ],
            "patternSet": null
        },
        {
            "code": "FTWCN6",
            "area": "area3",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "digitalCamo"
            ],
            "patternSet": null
        },
        {
            "code": "FTWCN6",
            "area": "area4",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "digitalCamo"
            ],
            "patternSet": null
        },
        {
            "code": "FTWCN6",
            "area": "area5",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "digitalCamo"
            ],
            "patternSet": null
        }
    ],
    "FTWCN7": [
        {
            "code": "FTWCN7",
            "area": "area1",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "heather"
            ],
            "patternSet": null
        },
        {
            "code": "FTWCN7",
            "area": "area2",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "heather"
            ],
            "patternSet": null
        },
        {
            "code": "FTWCN7",
            "area": "area3",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "heather"
            ],
            "patternSet": null
        },
        {
            "code": "FTWCN7",
            "area": "area4",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "heather"
            ],
            "patternSet": null
        },
        {
            "code": "FTWCN7",
            "area": "area5",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "heather"
            ],
            "patternSet": null
        }
    ],
    "WNYMJ": [
        {
            "code": "WNYMJ",
            "area": "area2",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "proStripe",
                "triadStripe"
            ],
            "patternSet": null
        }
    ],
    "WHBJ": [
        {
            "code": "WHBJ",
            "area": "area1",
            "colorSet": "onlyWhite",
            "patterns": [],
            "patternSet": null
        },
        {
            "code": "WHBJ",
            "area": "area3",
            "colorSet": "onlyBlueGray",
            "patterns": [],
            "patternSet": null
        }
    ]
}