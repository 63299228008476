module.exports={
    "vt": {
        "name": "Sublimated Twill",
        "code": "vt",
        "abbr": "VT",
        "clothingType": "jersey",
        "limitations": {
            "jersey": {
                "teamName": {
                    "size": [
                        "3",
                        "4"
                    ],
                    "fill": {
                        "pattern": "standard",
                        "color_1": "sublimated",
                        "color_2": "sublimated",
                        "color_3": "sublimated",
                        "color_4": "sublimated"
                    },
                    "layout": [
                        "straight",
                        "verticalArch",
                        "angled",
                        "slanted",
                        "bridgedArch"
                    ],
                    "outline_1": "sublimatedOptional",
                    "outline_2": "sublimatedOptional",
                    "tailing": {
                        "fill": "sublimated",
                        "outline": "sublimatedOptional",
                        "font": [
                            "anaheim",
                            "arizona",
                            "brushScript",
                            "connecticut",
                            "fancy",
                            "fullBlock",
                            "maryland",
                            "method",
                            "miami",
                            "plainBlock",
                            "script",
                            "monolith",
                            "stinger",
                            "rock"
                        ]
                    },
                    "isAvailable": true,
                    "font": [
                        "anaheim",
                        "arizona",
                        "brushScript",
                        "connecticut",
                        "fancy",
                        "fullBlock",
                        "maryland",
                        "method",
                        "miami",
                        "plainBlock",
                        "script",
                        "monolith",
                        "stinger",
                        "rock"
                    ]
                },
                "playerName": {
                    "placement": [
                        "none",
                        "14"
                    ],
                    "size": [
                        "2",
                        "2.5"
                    ],
                    "fill": {
                        "pattern": "standard",
                        "color_1": "sublimated",
                        "color_2": "sublimated",
                        "color_3": "sublimated",
                        "color_4": "sublimated"
                    },
                    "outline_1": "sublimatedOptional",
                    "outline_2": "sublimatedOptional",
                    "isAvailable": true,
                    "font": [
                        "anaheim",
                        "arizona",
                        "brushScript",
                        "connecticut",
                        "fancy",
                        "fullBlock",
                        "maryland",
                        "method",
                        "miami",
                        "plainBlock",
                        "script",
                        "monolith",
                        "stinger",
                        "rock"
                    ]
                },
                "number": {
                    "front": {
                        "fill": {
                            "pattern": "standard",
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated",
                            "color_4": "sublimated"
                        },
                        "outline_1": "sublimatedOptional",
                        "outline_2": "sublimatedOptional",
                        "isAvailable": true,
                        "font": [
                            "anaheimNumber",
                            "arizona47",
                            "connecticutNumber",
                            "fancy47",
                            "fullBlockNumber",
                            "marylandNumber",
                            "methodNumber",
                            "miami47",
                            "plainBlockNumber",
                            "monolithNumber",
                            "stingerNumber",
                            "rockNumber"
                        ],
                        "placement": [
                            "none",
                            "4",
                            "6"
                        ],
                        "size": [
                            "3",
                            "4"
                        ]
                    },
                    "sleeve": {
                        "fill": {
                            "pattern": "standard",
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated",
                            "color_4": "sublimated"
                        },
                        "outline_1": "sublimatedOptional",
                        "outline_2": "sublimatedOptional",
                        "isAvailable": true,
                        "font": [
                            "anaheimNumber",
                            "arizona47",
                            "connecticutNumber",
                            "fancy47",
                            "fullBlockNumber",
                            "marylandNumber",
                            "methodNumber",
                            "miami47",
                            "plainBlockNumber",
                            "monolithNumber",
                            "stingerNumber",
                            "rockNumber"
                        ],
                        "placement": [
                            "none",
                            "17L",
                            "17R"
                        ],
                        "size": [
                            "3",
                            "4"
                        ]
                    },
                    "back": {
                        "fill": {
                            "pattern": "standard",
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated",
                            "color_4": "sublimated"
                        },
                        "outline_1": "sublimatedOptional",
                        "outline_2": "sublimatedOptional",
                        "isAvailable": true,
                        "font": [
                            "anaheimNumber",
                            "arizona47",
                            "connecticutNumber",
                            "fancy47",
                            "fullBlockNumber",
                            "marylandNumber",
                            "methodNumber",
                            "miami47",
                            "plainBlockNumber",
                            "monolithNumber",
                            "stingerNumber",
                            "rockNumber"
                        ],
                        "placement": [
                            "none",
                            "30"
                        ],
                        "size": [
                            "6",
                            "8"
                        ]
                    }
                },
                "logo": {
                    "stock": {
                        "placement": [
                            "none",
                            "1",
                            "2",
                            "3",
                            "4",
                            "6",
                            "17L",
                            "17R",
                            "14"
                        ],
                        "fill": {
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated"
                        },
                        "isAvailable": true
                    },
                    "custom": {
                        "placement": [
                            "none",
                            "1",
                            "2",
                            "3",
                            "4",
                            "6",
                            "17L",
                            "17R",
                            "14"
                        ],
                        "isAvailable": true
                    }
                },
                "watermarkText": {
                    "isAvailable": false,
                    "font": []
                },
                "watermarkLogo": {
                    "stock": {
                        "isAvailable": false
                    },
                    "custom": {
                        "isAvailable": false
                    }
                }
            }
        }
    },
    "rvt": {
        "name": "Reflective Sublimated Twill",
        "code": "rvt",
        "abbr": "RVT",
        "clothingType": "jersey",
        "limitations": {
            "jersey": {
                "teamName": {
                    "size": [
                        "3",
                        "4"
                    ],
                    "fill": {
                        "pattern": "standard",
                        "color_1": "sublimated",
                        "color_2": "sublimated",
                        "color_3": "sublimated",
                        "color_4": "sublimated"
                    },
                    "layout": [
                        "straight",
                        "verticalArch",
                        "angled",
                        "slanted",
                        "bridgedArch"
                    ],
                    "outline_1": "RVTOptional",
                    "outline_2": "RVTOptional",
                    "tailing": {
                        "fill": "sublimated",
                        "outline": "RVTOptional",
                        "font": [
                            "anaheim",
                            "arizona",
                            "brushScript",
                            "connecticut",
                            "fancy",
                            "fullBlock",
                            "maryland",
                            "method",
                            "miami",
                            "plainBlock",
                            "script",
                            "monolith",
                            "stinger",
                            "rock"
                        ]
                    },
                    "isAvailable": true,
                    "font": [
                        "anaheim",
                        "arizona",
                        "brushScript",
                        "connecticut",
                        "fancy",
                        "fullBlock",
                        "maryland",
                        "method",
                        "miami",
                        "plainBlock",
                        "script",
                        "monolith",
                        "stinger",
                        "rock"
                    ]
                },
                "playerName": {
                    "placement": [
                        "none",
                        "14"
                    ],
                    "size": [
                        "2",
                        "2.5"
                    ],
                    "fill": {
                        "pattern": "standard",
                        "color_1": "sublimated",
                        "color_2": "sublimated",
                        "color_3": "sublimated",
                        "color_4": "sublimated"
                    },
                    "outline_1": "RVTOptional",
                    "outline_2": "RVTOptional",
                    "isAvailable": true,
                    "font": [
                        "anaheim",
                        "arizona",
                        "brushScript",
                        "connecticut",
                        "fancy",
                        "fullBlock",
                        "maryland",
                        "method",
                        "miami",
                        "plainBlock",
                        "script",
                        "monolith",
                        "stinger",
                        "rock"
                    ]
                },
                "number": {
                    "front": {
                        "fill": {
                            "pattern": "standard",
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated",
                            "color_4": "sublimated"
                        },
                        "outline_1": "RVTOptional",
                        "outline_2": "RVTOptional",
                        "isAvailable": true,
                        "font": [
                            "anaheimNumber",
                            "arizona47",
                            "brushScriptNumber",
                            "connecticutNumber",
                            "fancy47",
                            "fullBlockNumber",
                            "marylandNumber",
                            "methodNumber",
                            "miami47",
                            "plainBlockNumber",
                            "monolithNumber",
                            "stingerNumber",
                            "rockNumber"
                        ],
                        "placement": [
                            "none",
                            "4",
                            "6"
                        ],
                        "size": [
                            "3",
                            "4"
                        ]
                    },
                    "sleeve": {
                        "fill": {
                            "pattern": "standard",
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated",
                            "color_4": "sublimated"
                        },
                        "outline_1": "RVTOptional",
                        "outline_2": "RVTOptional",
                        "isAvailable": true,
                        "font": [
                            "anaheimNumber",
                            "arizona47",
                            "brushScriptNumber",
                            "connecticutNumber",
                            "fancy47",
                            "fullBlockNumber",
                            "marylandNumber",
                            "methodNumber",
                            "miami47",
                            "plainBlockNumber",
                            "monolithNumber",
                            "stingerNumber",
                            "rockNumber"
                        ],
                        "placement": [
                            "none",
                            "17L",
                            "17R"
                        ],
                        "size": [
                            "3",
                            "4"
                        ]
                    },
                    "back": {
                        "fill": {
                            "pattern": "standard",
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated",
                            "color_4": "sublimated"
                        },
                        "outline_1": "RVTOptional",
                        "outline_2": "RVTOptional",
                        "isAvailable": true,
                        "font": [
                            "anaheimNumber",
                            "arizona47",
                            "brushScriptNumber",
                            "connecticutNumber",
                            "fancy47",
                            "fullBlockNumber",
                            "marylandNumber",
                            "methodNumber",
                            "miami47",
                            "plainBlockNumber",
                            "monolithNumber",
                            "stingerNumber",
                            "rockNumber"
                        ],
                        "placement": [
                            "none",
                            "30"
                        ],
                        "size": [
                            "6",
                            "8"
                        ]
                    }
                },
                "logo": {
                    "stock": {
                        "placement": [
                            "none",
                            "1",
                            "2",
                            "3",
                            "4",
                            "6",
                            "17L",
                            "17R",
                            "14"
                        ],
                        "fill": {
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated"
                        },
                        "isAvailable": true
                    },
                    "custom": {
                        "placement": [
                            "none",
                            "1",
                            "2",
                            "3",
                            "4",
                            "6",
                            "17L",
                            "17R",
                            "14"
                        ],
                        "isAvailable": true
                    }
                },
                "watermarkText": {
                    "isAvailable": false,
                    "font": []
                },
                "watermarkLogo": {
                    "stock": {
                        "isAvailable": false
                    },
                    "custom": {
                        "isAvailable": false
                    }
                }
            }
        }
    },
    "fullSublimation": {
        "name": "Full Sublimation",
        "code": "fullSublimation",
        "abbr": "FULL",
        "clothingType": "jersey",
        "limitations": {
            "jersey": {
                "teamName": {
                    "size": [
                        "3",
                        "4"
                    ],
                    "fill": {
                        "pattern": "standard",
                        "color_1": "sublimated",
                        "color_2": "sublimated",
                        "color_3": "sublimated",
                        "color_4": "sublimated"
                    },
                    "layout": [
                        "straight",
                        "verticalArch",
                        "angled",
                        "slanted",
                        "bridgedArch"
                    ],
                    "outline_1": "sublimatedOptional",
                    "outline_2": "sublimatedOptional",
                    "tailing": {
                        "fill": "sublimated",
                        "outline": "sublimatedOptional",
                        "font": [
                            "anaheim",
                            "arizona",
                            "brushScript",
                            "connecticut",
                            "fancy",
                            "fullBlock",
                            "maryland",
                            "method",
                            "miami",
                            "plainBlock",
                            "script",
                            "monolith",
                            "stinger",
                            "rock"
                        ]
                    },
                    "isAvailable": true,
                    "font": [
                        "anaheim",
                        "arizona",
                        "brushScript",
                        "connecticut",
                        "fancy",
                        "fullBlock",
                        "maryland",
                        "method",
                        "miami",
                        "plainBlock",
                        "script",
                        "monolith",
                        "stinger",
                        "rock"
                    ]
                },
                "playerName": {
                    "placement": [
                        "none",
                        "14"
                    ],
                    "size": [
                        "2",
                        "2.5"
                    ],
                    "fill": {
                        "pattern": "standard",
                        "color_1": "sublimated",
                        "color_2": "sublimated",
                        "color_3": "sublimated",
                        "color_4": "sublimated"
                    },
                    "outline_1": "sublimatedOptional",
                    "outline_2": "sublimatedOptional",
                    "isAvailable": true,
                    "font": [
                        "anaheim",
                        "arizona",
                        "brushScript",
                        "connecticut",
                        "fancy",
                        "fullBlock",
                        "maryland",
                        "method",
                        "miami",
                        "plainBlock",
                        "script",
                        "monolith",
                        "stinger",
                        "rock"
                    ]
                },
                "number": {
                    "front": {
                        "fill": {
                            "pattern": "standard",
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated",
                            "color_4": "sublimated"
                        },
                        "outline_1": "sublimatedOptional",
                        "outline_2": "sublimatedOptional",
                        "isAvailable": true,
                        "font": [
                            "anaheimNumber",
                            "arizona47",
                            "brushScriptNumber",
                            "connecticutNumber",
                            "fancy47",
                            "fullBlockNumber",
                            "marylandNumber",
                            "methodNumber",
                            "miami47",
                            "plainBlockNumber",
                            "monolithNumber",
                            "stingerNumber",
                            "rockNumber"
                        ],
                        "placement": [
                            "none",
                            "4",
                            "6"
                        ],
                        "size": [
                            "3",
                            "4"
                        ]
                    },
                    "sleeve": {
                        "fill": {
                            "pattern": "standard",
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated",
                            "color_4": "sublimated"
                        },
                        "outline_1": "sublimatedOptional",
                        "outline_2": "sublimatedOptional",
                        "isAvailable": true,
                        "font": [
                            "anaheimNumber",
                            "arizona47",
                            "brushScriptNumber",
                            "connecticutNumber",
                            "fancy47",
                            "fullBlockNumber",
                            "marylandNumber",
                            "methodNumber",
                            "miami47",
                            "plainBlockNumber",
                            "monolithNumber",
                            "stingerNumber",
                            "rockNumber"
                        ],
                        "placement": [
                            "none",
                            "17L",
                            "17R"
                        ],
                        "size": [
                            "3",
                            "4"
                        ]
                    },
                    "back": {
                        "fill": {
                            "pattern": "standard",
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated",
                            "color_4": "sublimated"
                        },
                        "outline_1": "sublimatedOptional",
                        "outline_2": "sublimatedOptional",
                        "isAvailable": true,
                        "font": [
                            "anaheimNumber",
                            "arizona47",
                            "brushScriptNumber",
                            "connecticutNumber",
                            "fancy47",
                            "fullBlockNumber",
                            "marylandNumber",
                            "methodNumber",
                            "miami47",
                            "plainBlockNumber",
                            "monolithNumber",
                            "stingerNumber",
                            "rockNumber"
                        ],
                        "placement": [
                            "none",
                            "30"
                        ],
                        "size": [
                            "6",
                            "8"
                        ]
                    }
                },
                "logo": {
                    "stock": {
                        "placement": [
                            "none",
                            "1",
                            "2",
                            "3",
                            "4",
                            "6",
                            "17L",
                            "17R",
                            "14"
                        ],
                        "fill": {
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated"
                        },
                        "isAvailable": true
                    },
                    "custom": {
                        "placement": [
                            "none",
                            "1",
                            "2",
                            "3",
                            "4",
                            "6",
                            "17L",
                            "17R",
                            "14"
                        ],
                        "isAvailable": true
                    }
                },
                "watermarkText": {
                    "isAvailable": true,
                    "font": [
                        "anaheim",
                        "arizona",
                        "brushScript",
                        "connecticut",
                        "fancy",
                        "fullBlock",
                        "maryland",
                        "method",
                        "miami",
                        "plainBlock",
                        "script",
                        "monolith",
                        "stinger",
                        "rock"
                    ]
                },
                "watermarkLogo": {
                    "stock": {
                        "isAvailable": true
                    },
                    "custom": {
                        "isAvailable": true
                    }
                }
            }
        }
    },
    "fullSublimationCHI": {
        "name": "Full Sublimation",
        "code": "fullSublimationCHI",
        "abbr": "FULL",
        "clothingType": "jersey",
        "limitations": {
            "jersey": {
                "teamName": {
                    "size": [
                        "3",
                        "3.5"
                    ],
                    "fill": {
                        "pattern": "standard",
                        "color_1": "sublimated",
                        "color_2": "sublimated",
                        "color_3": "sublimated",
                        "color_4": "sublimated"
                    },
                    "layout": [
                        "straight",
                        "verticalArch",
                        "angled",
                        "slanted",
                        "bridgedArch"
                    ],
                    "outline_1": "sublimatedOptional",
                    "outline_2": "sublimatedOptional",
                    "tailing": {
                        "fill": "sublimated",
                        "outline": "sublimatedOptional",
                        "font": [
                            "anaheim",
                            "arizona",
                            "brushScript",
                            "connecticut",
                            "fancy",
                            "fullBlock",
                            "maryland",
                            "method",
                            "miami",
                            "plainBlock",
                            "script",
                            "monolith",
                            "stinger",
                            "rock"
                        ]
                    },
                    "isAvailable": true,
                    "font": [
                        "anaheim",
                        "arizona",
                        "brushScript",
                        "connecticut",
                        "fancy",
                        "fullBlock",
                        "maryland",
                        "method",
                        "miami",
                        "plainBlock",
                        "script",
                        "monolith",
                        "stinger",
                        "rock"
                    ]
                },
                "playerName": {
                    "placement": [
                        "none",
                        "14"
                    ],
                    "size": [
                        "2",
                        "2.5"
                    ],
                    "fill": {
                        "pattern": "standard",
                        "color_1": "sublimated",
                        "color_2": "sublimated",
                        "color_3": "sublimated",
                        "color_4": "sublimated"
                    },
                    "outline_1": "sublimatedOptional",
                    "outline_2": "sublimatedOptional",
                    "isAvailable": true,
                    "font": [
                        "anaheim",
                        "arizona",
                        "brushScript",
                        "connecticut",
                        "fancy",
                        "fullBlock",
                        "maryland",
                        "method",
                        "miami",
                        "plainBlock",
                        "script",
                        "monolith",
                        "stinger",
                        "rock"
                    ]
                },
                "number": {
                    "front": {
                        "fill": {
                            "pattern": "standard",
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated",
                            "color_4": "sublimated"
                        },
                        "outline_1": "sublimatedOptional",
                        "outline_2": "sublimatedOptional",
                        "isAvailable": true,
                        "font": [
                            "anaheimNumber",
                            "arizona47",
                            "brushScriptNumber",
                            "connecticutNumber",
                            "fancy47",
                            "fullBlockNumber",
                            "marylandNumber",
                            "methodNumber",
                            "miami47",
                            "plainBlockNumber",
                            "monolithNumber",
                            "stingerNumber",
                            "rockNumber"
                        ],
                        "placement": [
                            "none",
                            "4",
                            "6"
                        ],
                        "size": [
                            "3",
                            "4"
                        ]
                    },
                    "sleeve": {
                        "fill": {
                            "pattern": "standard",
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated",
                            "color_4": "sublimated"
                        },
                        "outline_1": "sublimatedOptional",
                        "outline_2": "sublimatedOptional",
                        "isAvailable": true,
                        "font": [
                            "anaheimNumber",
                            "arizona47",
                            "brushScriptNumber",
                            "connecticutNumber",
                            "fancy47",
                            "fullBlockNumber",
                            "marylandNumber",
                            "methodNumber",
                            "miami47",
                            "plainBlockNumber",
                            "monolithNumber",
                            "stingerNumber",
                            "rockNumber"
                        ],
                        "placement": [
                            "none",
                            "17L",
                            "17R"
                        ],
                        "size": [
                            "3",
                            "4"
                        ]
                    },
                    "back": {
                        "fill": {
                            "pattern": "standard",
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated",
                            "color_4": "sublimated"
                        },
                        "outline_1": "sublimatedOptional",
                        "outline_2": "sublimatedOptional",
                        "isAvailable": true,
                        "font": [
                            "anaheimNumber",
                            "arizona47",
                            "brushScriptNumber",
                            "connecticutNumber",
                            "fancy47",
                            "fullBlockNumber",
                            "marylandNumber",
                            "methodNumber",
                            "miami47",
                            "plainBlockNumber",
                            "monolithNumber",
                            "stingerNumber",
                            "rockNumber"
                        ],
                        "placement": [
                            "none",
                            "30"
                        ],
                        "size": [
                            "6",
                            "8"
                        ]
                    }
                },
                "logo": {
                    "stock": {
                        "placement": [
                            "none",
                            "1",
                            "2",
                            "3",
                            "4",
                            "6",
                            "17L",
                            "17R",
                            "14"
                        ],
                        "fill": {
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated"
                        },
                        "isAvailable": true
                    },
                    "custom": {
                        "placement": [
                            "none",
                            "1",
                            "2",
                            "3",
                            "4",
                            "6",
                            "17L",
                            "17R",
                            "14"
                        ],
                        "isAvailable": true
                    }
                },
                "watermarkText": {
                    "isAvailable": true,
                    "font": [
                        "anaheim",
                        "arizona",
                        "brushScript",
                        "connecticut",
                        "fancy",
                        "fullBlock",
                        "maryland",
                        "method",
                        "miami",
                        "plainBlock",
                        "script",
                        "monolith",
                        "stinger",
                        "rock"
                    ]
                },
                "watermarkLogo": {
                    "stock": {
                        "isAvailable": true
                    },
                    "custom": {
                        "isAvailable": true
                    }
                }
            }
        }
    },
    "fasttrack": {
        "name": "Fasttrack Sublimated Jerseys",
        "code": "fasttrack",
        "abbr": "FAST",
        "clothingType": "jersey",
        "limitations": {
            "jersey": {
                "teamName": {
                    "size": [
                        "3",
                        "4"
                    ],
                    "fill": {
                        "pattern": "standard",
                        "color_1": "sublimated",
                        "color_2": "sublimated",
                        "color_3": "sublimated",
                        "color_4": "sublimated"
                    },
                    "layout": [
                        "straight",
                        "verticalArch",
                        "angled"
                    ],
                    "outline_1": "sublimatedOptional",
                    "outline_2": "sublimatedOptional",
                    "tailing": {
                        "fill": "sublimated",
                        "outline": "sublimatedOptional",
                        "font": [
                            "connecticut",
                            "fullBlock",
                            "method",
                            "miami",
                            "script",
                            "monolith",
                            "stinger",
                            "rock"
                        ]
                    },
                    "isAvailable": true,
                    "font": [
                        "connecticut",
                        "fullBlock",
                        "method",
                        "miami",
                        "script",
                        "monolith",
                        "stinger",
                        "rock"
                    ]
                },
                "playerName": {
                    "placement": [
                        "none",
                        "14"
                    ],
                    "size": [
                        "2",
                        "2.5"
                    ],
                    "fill": {
                        "pattern": "standard",
                        "color_1": "sublimated",
                        "color_2": "sublimated",
                        "color_3": "sublimated",
                        "color_4": "sublimated"
                    },
                    "outline_1": "sublimatedOptional",
                    "outline_2": "sublimatedOptional",
                    "isAvailable": true,
                    "font": [
                        "connecticut",
                        "fullBlock",
                        "method",
                        "miami",
                        "script",
                        "monolith",
                        "stinger",
                        "rock"
                    ]
                },
                "number": {
                    "front": {
                        "fill": {
                            "pattern": "standard",
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated",
                            "color_4": "sublimated"
                        },
                        "outline_1": "sublimatedOptional",
                        "outline_2": "sublimatedOptional",
                        "isAvailable": true,
                        "font": [
                            "connecticutNumber",
                            "fullBlockNumber",
                            "methodNumber",
                            "miami47",
                            "monolithNumber",
                            "stingerNumber",
                            "rockNumber"
                        ],
                        "placement": [
                            "none",
                            "4",
                            "6"
                        ],
                        "size": [
                            "3",
                            "4"
                        ]
                    },
                    "sleeve": {
                        "fill": {
                            "pattern": "standard",
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated",
                            "color_4": "sublimated"
                        },
                        "outline_1": "sublimatedOptional",
                        "outline_2": "sublimatedOptional",
                        "isAvailable": true,
                        "font": [
                            "connecticutNumber",
                            "fullBlockNumber",
                            "methodNumber",
                            "miami47",
                            "monolithNumber",
                            "stingerNumber",
                            "rockNumber"
                        ],
                        "placement": [
                            "none"
                        ],
                        "size": [
                            "3",
                            "4"
                        ]
                    },
                    "back": {
                        "fill": {
                            "pattern": "standard",
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated",
                            "color_4": "sublimated"
                        },
                        "outline_1": "sublimatedOptional",
                        "outline_2": "sublimatedOptional",
                        "isAvailable": true,
                        "font": [
                            "connecticutNumber",
                            "fullBlockNumber",
                            "methodNumber",
                            "miami47",
                            "monolithNumber",
                            "stingerNumber",
                            "rockNumber"
                        ],
                        "placement": [
                            "none",
                            "30"
                        ],
                        "size": [
                            "6",
                            "8"
                        ]
                    }
                },
                "logo": {
                    "stock": {
                        "placement": [
                            "none",
                            "2",
                            "3",
                            "4",
                            "6",
                            "14"
                        ],
                        "fill": {
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated"
                        },
                        "isAvailable": true
                    },
                    "custom": {
                        "placement": [
                            "none"
                        ],
                        "isAvailable": true
                    }
                },
                "watermarkText": {
                    "isAvailable": false,
                    "font": []
                },
                "watermarkLogo": {
                    "stock": {
                        "isAvailable": false
                    },
                    "custom": {
                        "isAvailable": false
                    }
                }
            }
        }
    },
    "tg": {
        "name": "Thermal Graphic",
        "code": "tg",
        "abbr": "TG",
        "clothingType": "jersey",
        "limitations": {
            "jersey": {
                "teamName": {
                    "size": [
                        "4"
                    ],
                    "fill": {
                        "pattern": "standard",
                        "color_1": "sublimated",
                        "color_2": "sublimated",
                        "color_3": "sublimated",
                        "color_4": "sublimated"
                    },
                    "layout": [
                        "straight",
                        "verticalArch",
                        "angled"
                    ],
                    "outline_1": "sublimatedOptional",
                    "outline_2": "sublimatedOptional",
                    "tailing": {
                        "fill": "sublimated",
                        "outline": "sublimatedOptional",
                        "font": [
                            "connecticut",
                            "fullBlock",
                            "method",
                            "miami",
                            "script",
                            "monolith",
                            "stinger",
                            "rock"
                        ]
                    },
                    "isAvailable": true,
                    "font": [
                        "connecticut",
                        "fullBlock",
                        "method",
                        "miami",
                        "script",
                        "monolith",
                        "stinger",
                        "rock"
                    ]
                },
                "playerName": {
                    "placement": [
                        "none"
                    ],
                    "size": [
                        "2",
                        "2.5"
                    ],
                    "fill": {
                        "pattern": "standard",
                        "color_1": "sublimated",
                        "color_2": "sublimated",
                        "color_3": "sublimated",
                        "color_4": "sublimated"
                    },
                    "outline_1": "sublimatedOptional",
                    "outline_2": "sublimatedOptional",
                    "isAvailable": true,
                    "font": [
                        "connecticut",
                        "fullBlock",
                        "method",
                        "miami",
                        "script",
                        "monolith",
                        "stinger",
                        "rock"
                    ]
                },
                "number": {
                    "front": {
                        "fill": {
                            "pattern": "standard",
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated",
                            "color_4": "sublimated"
                        },
                        "outline_1": "sublimatedOptional",
                        "outline_2": "sublimatedOptional",
                        "isAvailable": true,
                        "font": [
                            "connecticutNumber",
                            "fullBlockNumber",
                            "methodNumber",
                            "miami47",
                            "monolithNumber",
                            "stingerNumber",
                            "rockNumber"
                        ],
                        "placement": [
                            "none",
                            "6"
                        ],
                        "size": [
                            "4"
                        ]
                    },
                    "sleeve": {
                        "fill": {
                            "pattern": "standard",
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated",
                            "color_4": "sublimated"
                        },
                        "outline_1": "sublimatedOptional",
                        "outline_2": "sublimatedOptional",
                        "isAvailable": true,
                        "font": [
                            "connecticutNumber",
                            "fullBlockNumber",
                            "methodNumber",
                            "miami47",
                            "monolithNumber",
                            "stingerNumber",
                            "rockNumber"
                        ],
                        "placement": [
                            "none"
                        ],
                        "size": [
                            "none"
                        ]
                    },
                    "back": {
                        "fill": {
                            "pattern": "standard",
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated",
                            "color_4": "sublimated"
                        },
                        "outline_1": "sublimatedOptional",
                        "outline_2": "sublimatedOptional",
                        "isAvailable": true,
                        "font": [
                            "connecticutNumber",
                            "fullBlockNumber",
                            "methodNumber",
                            "miami47",
                            "monolithNumber",
                            "stingerNumber",
                            "rockNumber"
                        ],
                        "placement": [
                            "none",
                            "30"
                        ],
                        "size": [
                            "8"
                        ]
                    }
                },
                "logo": {
                    "stock": {
                        "placement": [
                            "none"
                        ],
                        "fill": {
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated"
                        },
                        "isAvailable": true
                    },
                    "custom": {
                        "placement": [
                            "none"
                        ],
                        "isAvailable": true
                    }
                },
                "watermarkText": {
                    "isAvailable": false,
                    "font": []
                },
                "watermarkLogo": {
                    "stock": {
                        "isAvailable": false
                    },
                    "custom": {
                        "isAvailable": false
                    }
                }
            }
        }
    },
    "tgYouth": {
        "name": "Thermal Graphic",
        "code": "tgYouth",
        "abbr": "TG",
        "clothingType": "jersey",
        "limitations": {
            "jersey": {
                "teamName": {
                    "size": [
                        "3"
                    ],
                    "fill": {
                        "pattern": "standard",
                        "color_1": "sublimated",
                        "color_2": "sublimated",
                        "color_3": "sublimated",
                        "color_4": "sublimated"
                    },
                    "layout": [
                        "straight",
                        "verticalArch",
                        "angled"
                    ],
                    "outline_1": "sublimatedOptional",
                    "outline_2": "sublimatedOptional",
                    "tailing": {
                        "fill": "sublimated",
                        "outline": "sublimatedOptional",
                        "font": [
                            "connecticut",
                            "fullBlock",
                            "method",
                            "miami",
                            "script",
                            "monolith",
                            "stinger",
                            "rock"
                        ]
                    },
                    "isAvailable": true,
                    "font": [
                        "connecticut",
                        "fullBlock",
                        "method",
                        "miami",
                        "script",
                        "monolith",
                        "stinger",
                        "rock"
                    ]
                },
                "playerName": {
                    "placement": [
                        "none"
                    ],
                    "size": [
                        "2",
                        "2.5"
                    ],
                    "fill": {
                        "pattern": "standard",
                        "color_1": "sublimated",
                        "color_2": "sublimated",
                        "color_3": "sublimated",
                        "color_4": "sublimated"
                    },
                    "outline_1": "sublimatedOptional",
                    "outline_2": "sublimatedOptional",
                    "isAvailable": true,
                    "font": [
                        "connecticut",
                        "fullBlock",
                        "method",
                        "miami",
                        "script",
                        "monolith",
                        "stinger",
                        "rock"
                    ]
                },
                "number": {
                    "front": {
                        "fill": {
                            "pattern": "standard",
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated",
                            "color_4": "sublimated"
                        },
                        "outline_1": "sublimatedOptional",
                        "outline_2": "sublimatedOptional",
                        "isAvailable": true,
                        "font": [
                            "connecticutNumber",
                            "fullBlockNumber",
                            "methodNumber",
                            "miami47",
                            "monolithNumber",
                            "stingerNumber",
                            "rockNumber"
                        ],
                        "placement": [
                            "none",
                            "6"
                        ],
                        "size": [
                            "3"
                        ]
                    },
                    "sleeve": {
                        "fill": {
                            "pattern": "standard",
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated",
                            "color_4": "sublimated"
                        },
                        "outline_1": "sublimatedOptional",
                        "outline_2": "sublimatedOptional",
                        "isAvailable": true,
                        "font": [
                            "connecticutNumber",
                            "fullBlockNumber",
                            "methodNumber",
                            "miami47",
                            "monolithNumber",
                            "stingerNumber",
                            "rockNumber"
                        ],
                        "placement": [
                            "none"
                        ],
                        "size": [
                            "none"
                        ]
                    },
                    "back": {
                        "fill": {
                            "pattern": "standard",
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated",
                            "color_4": "sublimated"
                        },
                        "outline_1": "sublimatedOptional",
                        "outline_2": "sublimatedOptional",
                        "isAvailable": true,
                        "font": [
                            "connecticutNumber",
                            "fullBlockNumber",
                            "methodNumber",
                            "miami47",
                            "monolithNumber",
                            "stingerNumber",
                            "rockNumber"
                        ],
                        "placement": [
                            "none",
                            "30"
                        ],
                        "size": [
                            "6"
                        ]
                    }
                },
                "logo": {
                    "stock": {
                        "placement": [
                            "none"
                        ],
                        "fill": {
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated"
                        },
                        "isAvailable": true
                    },
                    "custom": {
                        "placement": [
                            "none"
                        ],
                        "isAvailable": true
                    }
                },
                "watermarkText": {
                    "isAvailable": false,
                    "font": []
                },
                "watermarkLogo": {
                    "stock": {
                        "isAvailable": false
                    },
                    "custom": {
                        "isAvailable": false
                    }
                }
            }
        }
    },
    "pantDeco": {
        "name": "Pant Decoration",
        "code": "pantDeco",
        "abbr": "RPD",
        "clothingType": "pant",
        "limitations": {
            "pant": {
                "teamName": {
                    "fill": {
                        "pattern": "standard",
                        "color_1": "sublimated",
                        "color_2": "sublimated",
                        "color_3": "sublimated",
                        "color_4": "sublimated"
                    },
                    "outline_1": "sublimatedOptional",
                    "outline_2": "sublimatedOptional",
                    "isAvailable": true,
                    "font": [
                        "anaheim",
                        "arizona",
                        "brushScript",
                        "connecticut",
                        "fancy",
                        "fullBlock",
                        "maryland",
                        "method",
                        "miami",
                        "plainBlock",
                        "script",
                        "monolith",
                        "stinger",
                        "rock"
                    ]
                },
                "logo": {
                    "stock": {
                        "fill": {
                            "color_1": "sublimated",
                            "color_2": "sublimated",
                            "color_3": "sublimated"
                        },
                        "isAvailable": true
                    },
                    "custom": {
                        "isAvailable": true
                    }
                }
            }
        }
    },
    "none": {
        "name": "None",
        "code": "none",
        "abbr": "NONE",
        "clothingType": "pant",
        "limitations": {
            "pant": {
                "teamName": {
                    "fill": {
                        "pattern": null,
                        "color_1": null,
                        "color_2": null,
                        "color_3": null,
                        "color_4": null
                    },
                    "outline_1": null,
                    "outline_2": null,
                    "isAvailable": false,
                    "font": []
                },
                "logo": {
                    "stock": {
                        "fill": {
                            "color_1": null,
                            "color_2": null,
                            "color_3": null
                        },
                        "isAvailable": false
                    },
                    "custom": {
                        "isAvailable": false
                    }
                }
            }
        }
    }
}