module.exports={
    "OP1": {
        "code": "OP1",
        "name": "OP1 Braid (Around neck, down front)"
    },
    "OP2": {
        "code": "OP2",
        "name": "OP2 Braid (Around edge of neck)"
    },
    "OP3": {
        "code": "OP3",
        "name": "OP3 Braid (Sleeve end/armhole)"
    },
    "OP4": {
        "code": "OP4",
        "name": "Decoration down sides (OP4)"
    },
    "OP4_2": {
        "code": "OP4_2",
        "name": "Decoration down sides (front&back) (OP4.2)"
    },
    "OP15": {
        "code": "OP15",
        "name": "Decoration around belt tunnels (OP15)"
    },
    "RIBWST": {
        "code": "RIBWST",
        "name": "Rib Knit Waist Band"
    },
    "ribKnitCollar": {
        "code": "ribKnitCollar",
        "name": "Collar"
    },
    "ribKnitCuff": {
        "code": "ribKnitCuff",
        "name": "Cuff"
    },
    "buttonColor": {
        "code": "buttonColor",
        "name": "Button Color"
    },
    "logoColor": {
        "code": "logoColor",
        "name": "Rawlings Logo Color"
    }
}