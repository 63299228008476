module.exports={
    "WHBJ": {
        "icon": "assets/product-icons/WHBJ.jpg?2014-09-30",
        "category": "stockJersey",
        "optionList": [],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WHBJ",
        "hasAssets": true,
        "modelId": "WHBJ",
        "textureId": "WHBJ",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulderInsertRightFront",
                    "shoulderInsertLeftFront",
                    "shoulderInsertRightBack",
                    "shoulderInsertLeftBack",
                    "sideInsertRightFront",
                    "sideInsertLeftFront",
                    "sideInsertRightBack",
                    "sideInsertLeftBack",
                    "*.collarPiping"
                ],
                "area2": [
                    "sleeveRight",
                    "sleeveLeft",
                    "front",
                    "back",
                    "placketRight",
                    "placketLeft",
                    "collar",
                    "placketRightInside",
                    "placketLeftInside",
                    "collarInside"
                ],
                "area3": [
                    "*.insertPiping"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body, Collar, Sleeves",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "WHBJ"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": null,
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "tg"
                ],
                "youth": [
                    "tgYouth"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WHBJ",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WHBJG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WRJ150": {
        "icon": "assets/product-icons/WRJ150.jpg?2014-09-30",
        "category": "stockJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WRJ150",
        "hasAssets": true,
        "modelId": "WJ150",
        "textureId": "WJ150",
        "asset": {
            "isFullButton": true,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "placketRight",
                    "placketLeft",
                    "frontLeft",
                    "frontRight",
                    "sleeveLeft",
                    "sleeveRight",
                    "collar",
                    "back"
                ],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "WJ150"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP3": true,
            "logoColor": null,
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "vt"
                ],
                "youth": [
                    "vt"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WRJ150",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WRJ150G",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "CW2B": {
        "icon": "assets/product-icons/CW2B.jpg?2014-09-30",
        "category": "customJerseys",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CW2B",
        "hasAssets": true,
        "modelId": "CW2B",
        "textureId": "CW2B",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "back",
                    "placketRight",
                    "placketRight1",
                    "placketLeft",
                    "placketLeft1",
                    "placketBack",
                    "front"
                ],
                "area3": [
                    "sleeveRight"
                ],
                "area4": [
                    "sleeveLeft"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Right Sleeve",
                "area4": "Left Sleeve",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customProJerseys",
                "customCollegiateJerseys",
                "customTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP3": true,
            "logoColor": "embroidery",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "vt",
                    "rvt"
                ],
                "youth": [
                    "vt",
                    "rvt"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CW2B",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "CW2BG",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Fill-in through 5/1/19"
    },
    "CWJI": {
        "icon": "assets/product-icons/CWJI.jpg?2014-09-30",
        "category": "customJerseys",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CWJI",
        "hasAssets": true,
        "modelId": "CWJI",
        "textureId": "CWJI",
        "asset": {
            "isFullButton": true,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "sleeveRight",
                    "sleeveLeft",
                    "frontRight",
                    "frontLeft",
                    "back",
                    "placketBack",
                    "placketFrontLeft",
                    "placketFrontRight",
                    "sideInsertLeftFront",
                    "sideInsertLeftBack",
                    "sideInsertRightBack",
                    "sideInsertRightFront",
                    "sleeveInsertRightBack",
                    "sleeveInsertRightFront",
                    "sleeveInsertLeftFront",
                    "sleeveInsertLeftBack"
                ],
                "area3": [
                    "insertBack1",
                    "insertFront1",
                    "insertBack",
                    "insertFront"
                ],
                "area4": [
                    "insertBack3",
                    "insertBack2",
                    "insertFront3",
                    "insertFront2"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Sleeve Inserts (front and back)",
                "area4": "Side Inserts (front and back)",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customProJerseys",
                "customCollegiateJerseys",
                "customTravelballJerseys"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP3": true,
            "logoColor": "embroidery",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "vt",
                    "rvt"
                ],
                "youth": [
                    "vt",
                    "rvt"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CWJI",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "CWJIG",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Fill-in through 5/1/19"
    },
    "CWMFF": {
        "icon": "assets/product-icons/CWMFF.jpg?2014-09-30",
        "category": "customJerseys",
        "optionList": [
            "OP1",
            "OP2",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "CWMFF",
        "hasAssets": true,
        "modelId": "WMFF",
        "textureId": "WMFF",
        "asset": {
            "isFullButton": true,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "back",
                    "frontRight",
                    "frontLeft",
                    "collar",
                    "placketRight",
                    "placketLeft",
                    "sleeveRight",
                    "sleeveLeft",
                    "sideInsertLeft",
                    "sideInsertRight"
                ],
                "area3": [
                    "sleeveInsertRight2",
                    "sleeveInsertLeft2",
                    "sleeveInsertLeftBack",
                    "sleeveInsertRightBack"
                ],
                "area4": [
                    "sideInsertRightTop",
                    "sideInsertLeftTop",
                    "sleeveInsertRight1",
                    "sleeveInsertLeft"
                ],
                "area5": [
                    "*.trim"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Sleeve Inserts (front and back)",
                "area4": "Side Inserts",
                "area5": "Trim on Sleeve End",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customProJerseys",
                "customCollegiateJerseys",
                "customTravelballJerseys"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP3": false,
            "logoColor": "embroidery",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "vt",
                    "rvt"
                ],
                "youth": [
                    "vt",
                    "rvt"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CWMFF",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": "Fill-in through 5/1/19"
    },
    "FXCFJ-SL": {
        "icon": "assets/product-icons/FXCFJ-SL.jpg?2014-09-30",
        "category": "customJerseys",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "FXCFJ-SL",
        "hasAssets": true,
        "modelId": "FXCFJ-SL",
        "textureId": "FXCFJ-SL",
        "asset": {
            "isFullButton": true,
            "isSleeveless": true,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "back",
                    "frontRight",
                    "frontLeft",
                    "placketRight",
                    "placketLeft",
                    "collar",
                    "button"
                ],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customProJerseys",
                "customCollegiateJerseys",
                "customTravelballJerseys"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP3": true,
            "logoColor": "embroidery",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "vt",
                    "rvt"
                ],
                "youth": [
                    "vt",
                    "rvt"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FXCFJSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FXCFJSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Fill-in through 5/1/19"
    },
    "FXCFJ-SS": {
        "icon": "assets/product-icons/FXCFJ-SS.jpg?2014-09-30",
        "category": "customJerseys",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "FXCFJ-SS",
        "hasAssets": true,
        "modelId": "FXCFJ-SS",
        "textureId": "FXCFJ-SS",
        "asset": {
            "isFullButton": true,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "back",
                    "frontRight",
                    "frontLeft",
                    "placketRight",
                    "placketLeft",
                    "collar",
                    "button"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Sleeves",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "customProJerseys",
                "customCollegiateJerseys",
                "customTravelballJerseys"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP3": true,
            "logoColor": "embroidery",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "vt",
                    "rvt"
                ],
                "youth": [
                    "vt",
                    "rvt"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FXCFJ",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FXCFJG",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Fill-in through 5/1/19"
    },
    "FTWVN1": {
        "icon": "assets/product-icons/FTWVN1.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTWVN1",
        "hasAssets": true,
        "modelId": "FTWVN1",
        "textureId": "FTWVN1",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.ftwvn1.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collar"
                ],
                "area3": [
                    "*.ftwvn1.color3"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Lower Sleeve / Bottom Neck",
                "area2": "Body / Upper neck",
                "area3": "Upper sleeve stripe",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "fasttrackSublimatedJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTWVN1",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FTWVN1G",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTWVN2": {
        "icon": "assets/product-icons/FTWVN2.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTWVN2",
        "hasAssets": true,
        "modelId": "FTWVN1",
        "textureId": "FTWVN1",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.shoulderInsert"
                ],
                "area5": [
                    "*.sideInsert"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Shoulder Insert",
                "area5": "Side Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "fasttrackSublimatedJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTWVN2",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FTWVN1G",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTWVN3": {
        "icon": "assets/product-icons/FTWVN3.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTWVN3",
        "hasAssets": true,
        "modelId": "FTWVN1",
        "textureId": "FTWVN1",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.ftwvn3.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collar"
                ],
                "area3": [
                    "*.ftwvn3.color3"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Sleeve End/Outline/Neck Stripe",
                "area2": "Body/Sleeve/Top of Neck",
                "area3": "Side Insert",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "fasttrackSublimatedJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTWVN3",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FTWVN1G",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTWVN4": {
        "icon": "assets/product-icons/FTWVN4.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTWVN4",
        "hasAssets": true,
        "modelId": "FTWVN1",
        "textureId": "FTWVN1",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "*.ftwvn4.color3"
                ],
                "area4": [
                    "*.ftwvn4.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Outside Insert",
                "area4": "Middle Insert",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "fasttrackSublimatedJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTWVN4",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FTWVN1G",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTWVN5": {
        "icon": "assets/product-icons/FTWVN5.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTWVN5",
        "hasAssets": true,
        "modelId": "FTWVN1",
        "textureId": "FTWVN1",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.ftwvn5.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Neck",
                "area2": "Body",
                "area3": "Fade Design Sleeves",
                "area4": "Fade Design Side Inserts",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "fasttrackSublimatedJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTWVN5",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FTWVN1G",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTWVN6": {
        "icon": "assets/product-icons/FTWVN6.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTWVN6",
        "hasAssets": true,
        "modelId": "FTWVN1",
        "textureId": "FTWVN1",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.shoulderInsert"
                ],
                "area5": [
                    "*.sideInsert"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Shoulder Insert",
                "area5": "Side Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "fasttrackSublimatedJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTWVN6",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FTWVN1G",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTWVN7": {
        "icon": "assets/product-icons/FTWVN7.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTWVN7",
        "hasAssets": true,
        "modelId": "FTWVN1",
        "textureId": "FTWVN1",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.shoulderInsert"
                ],
                "area5": [
                    "*.sideInsert"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Shoulder Insert",
                "area5": "Side Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "fasttrackSublimatedJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTWVN7",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FTWVN1G",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTWCN1": {
        "icon": "assets/product-icons/FTWCN1.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTWCN1",
        "hasAssets": true,
        "modelId": "FTWCN1",
        "textureId": "FTWCN1",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.ftwcn1.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collar"
                ],
                "area3": [
                    "*.ftwcn1.color3"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Lower Sleeve / Bottom Neck",
                "area2": "Body / Upper neck",
                "area3": "Upper sleeve stripe",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "fasttrackSublimatedJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTWCN1",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FTWCN1G",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTWCN2": {
        "icon": "assets/product-icons/FTWCN2.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTWCN2",
        "hasAssets": true,
        "modelId": "FTWCN1",
        "textureId": "FTWCN1",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.shoulderInsert"
                ],
                "area5": [
                    "*.sideInsert"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Shoulder Insert",
                "area5": "Side Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "fasttrackSublimatedJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTWCN2",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FTWCN1G",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTWCN3": {
        "icon": "assets/product-icons/FTWCN3.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTWCN3",
        "hasAssets": true,
        "modelId": "FTWCN1",
        "textureId": "FTWCN1",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.ftwcn3.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collar"
                ],
                "area3": [
                    "*.ftwcn3.color3"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Sleeve End/Outline/Neck Stripe",
                "area2": "Body/Sleeve/Top of Neck",
                "area3": "Side Insert",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "fasttrackSublimatedJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTWCN3",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FTWCN1G",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTWCN4": {
        "icon": "assets/product-icons/FTWCN4.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTWCN4",
        "hasAssets": true,
        "modelId": "FTWCN1",
        "textureId": "FTWCN1",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "*.ftwcn4.color3"
                ],
                "area4": [
                    "*.ftwcn4.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Outside Insert",
                "area4": "Middle Insert",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "fasttrackSublimatedJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTWCN4",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FTWCN1G",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTWCN5": {
        "icon": "assets/product-icons/FTWCN5.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTWCN5",
        "hasAssets": true,
        "modelId": "FTWCN1",
        "textureId": "FTWCN1",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.ftwcn5.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Neck",
                "area2": "Body",
                "area3": "Fade Design Sleeves",
                "area4": "Fade Design Side Inserts",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "fasttrackSublimatedJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTWCN5",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FTWCN1G",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTWCN6": {
        "icon": "assets/product-icons/FTWCN6.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTWCN6",
        "hasAssets": true,
        "modelId": "FTWCN1",
        "textureId": "FTWCN1",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.shoulderInsert"
                ],
                "area5": [
                    "*.sideInsert"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Shoulder Insert",
                "area5": "Side Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "fasttrackSublimatedJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTWCN6",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FTWCN1G",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "FTWCN7": {
        "icon": "assets/product-icons/FTWCN7.jpg?2014-09-30",
        "category": "fasttrackSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FTWCN7",
        "hasAssets": true,
        "modelId": "FTWCN1",
        "textureId": "FTWCN1",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.shoulderInsert"
                ],
                "area5": [
                    "*.sideInsert"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Shoulder Insert",
                "area5": "Side Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "fasttrackSublimatedJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fasttrack"
                ],
                "youth": [
                    "fasttrack"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FTWCN7",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FTWCN1G",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Only available in 100% Lightweight Moisture Wicking Polyester Fabric (36 cloth)"
    },
    "CW2BVF": {
        "icon": "assets/product-icons/CW2BVF.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CW2BVF",
        "hasAssets": true,
        "modelId": "CW2B",
        "textureId": "CW2B",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "back",
                    "placketRight",
                    "placketRight1",
                    "placketLeft",
                    "placketLeft1",
                    "placketBack",
                    "front"
                ],
                "area3": [
                    "sleeveRight"
                ],
                "area4": [
                    "sleeveLeft"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "jerseyBody",
                "area3": "jerseyBody",
                "area4": "jerseyBody",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Right Sleeve",
                "area4": "Left Sleeve",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CW2B-sub",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "CW2BG-sub",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WARZVN": {
        "icon": "assets/product-icons/WARZVN.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WARZVN",
        "hasAssets": true,
        "modelId": "WARZVN",
        "textureId": "WARZVN",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft",
                    "collar"
                ],
                "area3": [
                    "*.warzvn.color3"
                ],
                "area4": [
                    "*.warzvn.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Side Insert Accents",
                "area4": "Sleeve Accents Across Collar",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WARZVN",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WARZVNG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WARZVNSL": {
        "icon": "assets/product-icons/WARZVNSL.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WARZVNSL",
        "hasAssets": true,
        "modelId": "WVNSL",
        "textureId": "WVNSL",
        "asset": {
            "isFullButton": false,
            "isSleeveless": true,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "collar"
                ],
                "area3": [
                    "*.warzvnsl.area3"
                ],
                "area4": [
                    "*.warzvnsl.area4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Side Insert Accents",
                "area4": "Shoulder Accents and Across Collar",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WARZVNSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WARZVNSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WBBVN": {
        "icon": "assets/product-icons/WBBVN.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WBBVN",
        "hasAssets": true,
        "modelId": "WARZVN",
        "textureId": "WARZVN",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collar"
                ],
                "area3": [
                    "*.wbbvn.area3"
                ],
                "area4": [
                    "*.wbbvn.area4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Border Stripes Design",
                "area4": "Middle Stripe on Body / Sleeve Border",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": "m",
            "decorationPackages": {
                "adult": [
                    "fullSublimationCHI"
                ],
                "youth": [
                    "fullSublimationCHI"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WBBVN",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WBBVNG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WBBVNSL": {
        "icon": "assets/product-icons/WBBVNSL.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WBBVNSL",
        "hasAssets": true,
        "modelId": "WVNSL",
        "textureId": "WBBVNSL",
        "asset": {
            "isFullButton": false,
            "isSleeveless": true,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "collar"
                ],
                "area3": [
                    "*.wbbvnsl.area3"
                ],
                "area4": [
                    "*.wbbvnsl.area4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Border Stripes Design",
                "area4": "Middle Stripe on Body",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": "m",
            "decorationPackages": {
                "adult": [
                    "fullSublimationCHI"
                ],
                "youth": [
                    "fullSublimationCHI"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WBBVNSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WBBVNSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WCHIJ": {
        "icon": "assets/product-icons/WCHIJ.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WCHIJ",
        "hasAssets": true,
        "modelId": "WHOUSJ",
        "textureId": "WCHIJ",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "*.wchij.area3"
                ],
                "area4": [
                    "*.wchij.area4"
                ],
                "area5": [
                    "*.wchij.area5"
                ],
                "area6": [
                    "*.wchij.area6"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Middle Stripe",
                "area4": "Border Stripes",
                "area5": "Upper Collar",
                "area6": "Lower Collar",
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": "m",
            "decorationPackages": {
                "adult": [
                    "fullSublimationCHI"
                ],
                "youth": [
                    "fullSublimationCHI"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WCHIJ",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WCHIJG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WCHIVNSL": {
        "icon": "assets/product-icons/WCHIVNSL.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WCHIVNSL",
        "hasAssets": true,
        "modelId": "WVNSL",
        "textureId": "WCHIVNSL",
        "asset": {
            "isFullButton": false,
            "isSleeveless": true,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.wchivnsl.area3"
                ],
                "area4": [
                    "*.wchivnsl.area4"
                ],
                "area5": [
                    "collar"
                ],
                "area6": [
                    "*.wchivnsl.area6"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Middle Stripe",
                "area4": "Border Stripes",
                "area5": "Upper Collar",
                "area6": "Lower Collar",
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": "m",
            "decorationPackages": {
                "adult": [
                    "fullSublimationCHI"
                ],
                "youth": [
                    "fullSublimationCHI"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WCHIVNSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WCHIVNSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WCLNCHJ": {
        "icon": "assets/product-icons/WCLNCHJ.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WCLNCHJ",
        "hasAssets": true,
        "modelId": "WCLNCHJ",
        "textureId": "WCLNCHJ",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area3": [
                    "*.wclnchj.area3"
                ],
                "area4": [
                    "collar"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "noFade",
                "area4": "pattern",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Shoulder and Sleeve Inserts",
                "area4": "Collar",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WCLNCHJ",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WCLNCHJG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WDFVN": {
        "icon": "assets/product-icons/WDFVN.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WDFVN",
        "hasAssets": true,
        "modelId": "WARZVN",
        "textureId": "WARZVN",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "collar"
                ],
                "area3": [
                    "*.wdfvn.area3"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Upper Body",
                "area3": "Lower Body",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WDFVN",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WDFVNG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WDFVNSL": {
        "icon": "assets/product-icons/WDFVNSL.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WDFVNSL",
        "hasAssets": true,
        "modelId": "WVNSL",
        "textureId": "WVNSL",
        "asset": {
            "isFullButton": false,
            "isSleeveless": true,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "collar"
                ],
                "area3": [
                    "*.wdfvnsl.area3"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Upper Body",
                "area3": "Lower Body",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WDFVNSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WDFVNSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WHAVN": {
        "icon": "assets/product-icons/WHAVN.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WHAVN",
        "hasAssets": true,
        "modelId": "WHAVN",
        "textureId": "WHAVN",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.whavn.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area3": [
                    "*.whavn.color3"
                ],
                "area4": [
                    "*.whavn.color4"
                ],
                "area5": [
                    "*.whavn.color5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body/ Top of Sleeves",
                "area3": "Under sleeve/ Top of Insert",
                "area4": "Middle Insert",
                "area5": "Bottom Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WHAVN",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WHAVNG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WHAVNSL": {
        "icon": "assets/product-icons/WHAVNSL.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WHAVNSL",
        "hasAssets": true,
        "modelId": "WVNSL",
        "textureId": "WVNSL",
        "asset": {
            "isFullButton": false,
            "isSleeveless": true,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.whavnsl.area3"
                ],
                "area4": [
                    "*.whavnsl.area4"
                ],
                "area5": [
                    "*.whavnsl.area5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Top Insert",
                "area4": "Middle Insert",
                "area5": "Bottom Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WHAVNSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WHAVNSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WHOUSJ": {
        "icon": "assets/product-icons/WHOUSJ.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WHOUSJ",
        "hasAssets": true,
        "modelId": "WHOUSJ",
        "textureId": "WHOUSJ",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area5",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.whousj.area1"
                ],
                "area2": [
                    "*.whousj.area2"
                ],
                "area3": [
                    "*.whousj.area3"
                ],
                "area4": [
                    "*.whousj.area4"
                ],
                "area5": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area6": [
                    "*.whousj.area6"
                ],
                "area7": [
                    "*.whousj.area7"
                ]
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors"
            },
            "titles": {
                "area1": "Bottom of Jersey and Stripes",
                "area2": "Secondary Stripe",
                "area3": "Tertiary Stripe",
                "area4": "Sleeve End and Middle Stripe",
                "area5": "Chest",
                "area6": "Upper Collar",
                "area7": "Lower Collar"
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": [
                "watermarkText",
                "watermarkLogo.stock",
                "watermarkLogo.custom"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WHOUSJ",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WHOUSJG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WHOUSVNSL": {
        "icon": "assets/product-icons/WHOUSVNSL.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WHOUSVNSL",
        "hasAssets": true,
        "modelId": "WVNSL",
        "textureId": "WVNSL",
        "asset": {
            "isFullButton": false,
            "isSleeveless": true,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area5",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.whousvnsl.area1"
                ],
                "area2": [
                    "*.whousvnsl.area2"
                ],
                "area3": [
                    "*.whousvnsl.area3"
                ],
                "area4": [
                    "*.whousvnsl.area4"
                ],
                "area5": [
                    "front",
                    "back"
                ],
                "area6": [
                    "collar"
                ],
                "area7": [
                    "*.whousvnsl.area7"
                ]
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors"
            },
            "titles": {
                "area1": "Bottom Jersey and Stripes",
                "area2": "Secondary Stripes",
                "area3": "Tertiary Stripes",
                "area4": "Middle Stripe",
                "area5": "Chest",
                "area6": "Upper Collar",
                "area7": "Lower Collar"
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WHOUSVNSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WHOUSVNSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WHRDJ": {
        "icon": "assets/product-icons/WHRDJ.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WHRDJ",
        "hasAssets": true,
        "modelId": "WHOUSJ",
        "textureId": "WHOUSJ",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.whrdj.area3",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.whrdj.area4"
                ],
                "area5": [
                    "collar"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "jerseyBodyNoFade",
                "area4": "noFade",
                "area5": "pattern",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Shoulder and Sleeve",
                "area4": "Side Inserts",
                "area5": "Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WHRDJ",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WHRDJG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WHRDVNSL": {
        "icon": "assets/product-icons/WHRDVNSL.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WHRDVNSL",
        "hasAssets": true,
        "modelId": "WVNSL",
        "textureId": "WVNSL",
        "asset": {
            "isFullButton": false,
            "isSleeveless": true,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.whrdvnsl.area3"
                ],
                "area4": [
                    "*.whrdvnsl.area4"
                ],
                "area5": [
                    "collar"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "standard",
                "area4": "standard",
                "area5": "pattern",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Shoulder",
                "area4": "Side Inserts",
                "area5": "Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WHRDVNSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WHRDVNSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WKUJ": {
        "icon": "assets/product-icons/WKUJ.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": true
        },
        "code": "WKUJ",
        "hasAssets": true,
        "modelId": "WHOUSJ",
        "textureId": "WHOUSJ",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.wkuj.area1"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area3": [
                    "*.wkuj.area3"
                ],
                "area4": [
                    "collar"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "noFade",
                "area4": "pattern",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Piping",
                "area2": "Body",
                "area3": "Shoulder and Under Arm Insert",
                "area4": "Collar",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WKUJ",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WKUJG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WMBVN": {
        "icon": "assets/product-icons/WMBVN.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WMBVN",
        "hasAssets": true,
        "modelId": "WHOUSJ",
        "textureId": "WCHIJ",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.wmbvn.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "*.wmbvn.color3"
                ],
                "area4": [
                    "*.wmbvn.color4"
                ],
                "area5": [
                    "*.wmbvn.color5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Sleeve Insert",
                "area4": "Side Inserts",
                "area5": "Front Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WMBVN",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WMBVNG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WMBVNSL": {
        "icon": "assets/product-icons/WMBVNSL.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WMBVNSL",
        "hasAssets": true,
        "modelId": "WVNSL",
        "textureId": "WVNSL",
        "asset": {
            "isFullButton": false,
            "isSleeveless": true,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [],
                "area4": [
                    "*.wmbvnsl.area4"
                ],
                "area5": [
                    "*.wmbvnsl.area5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": null,
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": null,
                "area4": "Side Inserts",
                "area5": "Front Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WMBVNSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WMBVNSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WNYMJ": {
        "icon": "assets/product-icons/WNYMJ.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WNYMJ",
        "hasAssets": true,
        "modelId": "WHOUSJ",
        "textureId": "WHOUSJ",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.wnymj.area1"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "*.wnymj.area3"
                ],
                "area4": [
                    "*.wnymj.area4"
                ],
                "area5": [
                    "*.wnymj.area5"
                ],
                "area6": [
                    "*.wnymj.area6"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Inner Shoulder Stripe",
                "area4": "Outer Shoulder Stripe",
                "area5": "Inner Insert Stripe",
                "area6": "Outer Insert Stripe",
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WNYMJ",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WNYMJG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WOAKJ": {
        "icon": "assets/product-icons/WOAKJ.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WOAKJ",
        "hasAssets": true,
        "modelId": "WHOUSJ",
        "textureId": "WHOUSJ",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "*.woakj.area3"
                ],
                "area4": [
                    "*.woakj.area4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Neck and Sleeve Outer",
                "area4": "Neck and Sleeve Middle",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WOAKJ",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WOAKJG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WOAKVNSL": {
        "icon": "assets/product-icons/WOAKVNSL.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WOAKVNSL",
        "hasAssets": true,
        "modelId": "WVNSL",
        "textureId": "WVNSL",
        "asset": {
            "isFullButton": false,
            "isSleeveless": true,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "collar"
                ],
                "area3": [
                    "*.woakvnsl.area3"
                ],
                "area4": [
                    "*.woakvnsl.area4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Neck/Front Outer & Armhole Outer",
                "area4": "Neck/Front Middle & Armhole Middle",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WOAKVNSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WOAKVNSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WWINGVN": {
        "icon": "assets/product-icons/WWINGVN.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WWINGVN",
        "hasAssets": true,
        "modelId": "WARZVN",
        "textureId": "WARZVN",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.wwingvn.area1"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.wwingvn.area4"
                ],
                "area5": [
                    "*.wwingvn.area5"
                ],
                "area6": [
                    "collar"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": "Sleeve Piping",
                "area2": "Body",
                "area3": "Lower Sleeve",
                "area4": "Upper Sleeve",
                "area5": "Side Insert",
                "area6": "Collar",
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WWINGVN",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WWINGVNG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WWINGVNSL": {
        "icon": "assets/product-icons/WWINGVNSL.jpg?2014-09-30",
        "category": "sublimatedVNeckJersey",
        "optionList": [
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WWINGVNSL",
        "hasAssets": true,
        "modelId": "WVNSL",
        "textureId": "WVNSL",
        "asset": {
            "isFullButton": false,
            "isSleeveless": true,
            "hasButtons": false
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.wwingvnsl.area1"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.wwingvnsl.area3"
                ],
                "area4": [
                    "*.wwingvnsl.area4"
                ],
                "area5": [
                    "*.wwingvnsl.area5"
                ],
                "area6": [
                    "collar"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": "Shoulder Piping",
                "area2": "Body",
                "area3": "Armhole Insert",
                "area4": "Shoulder",
                "area5": "Side Insert",
                "area6": "Collar",
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WWINGVNSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WWINGVNSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "CWJIVF": {
        "icon": "assets/product-icons/CWJIVF.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CWJIVF",
        "hasAssets": true,
        "modelId": "CWJI",
        "textureId": "CWJI",
        "asset": {
            "isFullButton": true,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "sleeveRight",
                    "sleeveLeft",
                    "frontRight",
                    "frontLeft",
                    "back",
                    "placketBack",
                    "placketFrontLeft",
                    "placketFrontRight",
                    "sideInsertLeftFront",
                    "sideInsertLeftBack",
                    "sideInsertRightBack",
                    "sideInsertRightFront",
                    "sleeveInsertRightBack",
                    "sleeveInsertRightFront",
                    "sleeveInsertLeftFront",
                    "sleeveInsertLeftBack"
                ],
                "area3": [
                    "insertFront",
                    "insertFront1",
                    "insertBack1",
                    "insertBack"
                ],
                "area4": [
                    "insertBack3",
                    "insertBack2",
                    "insertFront3",
                    "insertFront2"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "jerseyBody",
                "area3": "standard",
                "area4": "standard",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Sleeve Inserts (front and back)",
                "area4": "Side Inserts (front and back)",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CWJI-sub",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "CWJIG-sub",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "CWMFFVF": {
        "icon": "assets/product-icons/CWMFFVF.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "CWMFFVF",
        "hasAssets": true,
        "modelId": "WMFF",
        "textureId": "WMFF",
        "asset": {
            "isFullButton": true,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "back",
                    "frontRight",
                    "frontLeft",
                    "collar",
                    "placketRight",
                    "placketLeft",
                    "sleeveRight",
                    "sleeveLeft",
                    "sideInsertLeft",
                    "sideInsertRight"
                ],
                "area3": [
                    "sleeveInsertRight2",
                    "sleeveInsertLeft2",
                    "sleeveInsertLeftBack",
                    "sleeveInsertRightBack"
                ],
                "area4": [
                    "sideInsertRightTop",
                    "sideInsertLeftTop",
                    "sleeveInsertRight1",
                    "sleeveInsertLeft"
                ],
                "area5": [
                    "*.trim"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "jerseyBody",
                "area3": "standard",
                "area4": "standard",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Sleeve Inserts (front and back)",
                "area4": "Side Inserts",
                "area5": "Trim on Sleeve End",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "CWMFF-sub",
                "price": null,
                "note": null
            },
            "cutType_youth": null
        },
        "skuNote": null
    },
    "FXCFJSLVF": {
        "icon": "assets/product-icons/FXCFJSLVF.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FXCFJSLVF",
        "hasAssets": true,
        "modelId": "FXCFJ-SL",
        "textureId": "FXCFJ-SL",
        "asset": {
            "isFullButton": false,
            "isSleeveless": true,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "back",
                    "frontRight",
                    "frontLeft",
                    "placketRight",
                    "placketLeft",
                    "collar",
                    "button"
                ],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "jerseyBody",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FXCFJSLVF",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FXCFJGSLVF",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "FXCFJVF-SS": {
        "icon": "assets/product-icons/FXCFJVF-SS.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FXCFJVF-SS",
        "hasAssets": true,
        "modelId": "FXCFJ-SS",
        "textureId": "FXCFJ-SS",
        "asset": {
            "isFullButton": true,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "back",
                    "frontRight",
                    "frontLeft",
                    "placketRight",
                    "placketLeft",
                    "collar"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "jerseyBody",
                "area3": "jerseyBody",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Sleeves",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FXCFJ-sub",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FXCFJG-sub",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WARZJ": {
        "icon": "assets/product-icons/WARZJ.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WARZJ",
        "hasAssets": true,
        "modelId": "WARZJ",
        "textureId": "WARZJ",
        "asset": {
            "isFullButton": true,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "back",
                    "sleeveRight",
                    "sleeveLeft",
                    "placketBack",
                    "placketFrontRight",
                    "placketFrontLeft"
                ],
                "area3": [
                    "*.warzj.area3"
                ],
                "area4": [
                    "*.warzj.area4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Side Insert Accent",
                "area4": "Sleeve Accents",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": false
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WARZJ",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WARZJG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WARZFBSL": {
        "icon": "assets/product-icons/WARZFBSL.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WARZFBSL",
        "hasAssets": true,
        "modelId": "WFBSL",
        "textureId": "WFBSL",
        "asset": {
            "isFullButton": true,
            "isSleeveless": true,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "placketLeft",
                    "placketRight",
                    "placketBack"
                ],
                "area3": [
                    "*.warzfbsl.area3"
                ],
                "area4": [
                    "*.warzfbsl.area4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Side Insert Accents",
                "area4": "Shoulder Accents and Across Collar",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WARZFBSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WARZFBSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WBBFB": {
        "icon": "assets/product-icons/WBBFB.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP2",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WBBFB",
        "hasAssets": true,
        "modelId": "WARZJ",
        "textureId": "WARZJ",
        "asset": {
            "isFullButton": true,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "back",
                    "sleeveRight",
                    "sleeveLeft",
                    "placketBack",
                    "placketFrontRight",
                    "placketFrontLeft"
                ],
                "area3": [
                    "*.wbbfb.area3"
                ],
                "area4": [
                    "*.wbbfb.area4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Border Stripes Design",
                "area4": "Middle Stripe on Body / Sleeve Border",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": "m",
            "decorationPackages": {
                "adult": [
                    "fullSublimationCHI"
                ],
                "youth": [
                    "fullSublimationCHI"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WBBFB",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WBBFBG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WBBFBSL": {
        "icon": "assets/product-icons/WBBFBSL.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WBBFBSL",
        "hasAssets": true,
        "modelId": "WFBSL",
        "textureId": "WBBFBSL",
        "asset": {
            "isFullButton": true,
            "isSleeveless": true,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "placketLeft",
                    "placketRight",
                    "placketBack"
                ],
                "area3": [
                    "*.wbbfbsl.area3"
                ],
                "area4": [
                    "*.wbbfbsl.area4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Border Stripes Design",
                "area4": "Middle Stripe on Body",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": "m",
            "decorationPackages": {
                "adult": [
                    "fullSublimationCHI"
                ],
                "youth": [
                    "fullSublimationCHI"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WBBFBSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WBBFBSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WCHIFB": {
        "icon": "assets/product-icons/WCHIFB.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WCHIFB",
        "hasAssets": true,
        "modelId": "FXCFJ-SS",
        "textureId": "FXCFJ-SS",
        "asset": {
            "isFullButton": true,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "back",
                    "frontRight",
                    "frontLeft",
                    "sleeveRight",
                    "sleeveLeft",
                    "placketRight",
                    "placketLeft",
                    "collar"
                ],
                "area3": [
                    "*.wchifb.color3"
                ],
                "area4": [
                    "*.wchifb.color4"
                ],
                "area5": [
                    "*.wchifb.color5"
                ],
                "area6": [
                    "*.wchifb.color6"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Middle Stripe",
                "area4": "Border Stripes",
                "area5": "Upper Collar",
                "area6": "Lower Collar",
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": "m",
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WCHIFB",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WCHIFBG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WCHIFBSL": {
        "icon": "assets/product-icons/WCHIFBSL.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WCHIFBSL",
        "hasAssets": true,
        "modelId": "WFBSL",
        "textureId": "WFBSL",
        "asset": {
            "isFullButton": true,
            "isSleeveless": true,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "placketLeft",
                    "placketRight",
                    "placketBack"
                ],
                "area3": [
                    "*.wchifbsl.area3"
                ],
                "area4": [
                    "*.wchifbsl.area4"
                ],
                "area5": [
                    "*.wchifbsl.area5"
                ],
                "area6": [
                    "*.wchifbsl.area6"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Middle Stripe",
                "area4": "Border Stripes",
                "area5": "Upper Collar",
                "area6": "Lower Collar",
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": "m",
            "decorationPackages": {
                "adult": [
                    "fullSublimationCHI"
                ],
                "youth": [
                    "fullSublimationCHI"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WCHIFBSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WCHIFBSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WDFFB": {
        "icon": "assets/product-icons/WDFFB.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WDFFB",
        "hasAssets": true,
        "modelId": "WARZJ",
        "textureId": "WARZJ",
        "asset": {
            "isFullButton": true,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "placketBack",
                    "placketFrontRight",
                    "placketFrontLeft"
                ],
                "area3": [
                    "*.wdffb.area3"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Upper Body",
                "area3": "Lower Body",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WDFFB",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WDFFBG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WDFFBSL": {
        "icon": "assets/product-icons/WDFFBSL.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WDFFBSL",
        "hasAssets": true,
        "modelId": "WFBSL",
        "textureId": "WFBSL",
        "asset": {
            "isFullButton": true,
            "isSleeveless": true,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "placketLeft",
                    "placketRight",
                    "placketBack"
                ],
                "area3": [
                    "*.wdffbsl.area3"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Upper Body",
                "area3": "Lower Body",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WDFFBSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WDFFBSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WHAFB": {
        "icon": "assets/product-icons/WHAFB.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WHAFB",
        "hasAssets": true,
        "modelId": "WARZJ",
        "textureId": "WARZJ",
        "asset": {
            "isFullButton": true,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "back",
                    "sleeveRight",
                    "sleeveLeft",
                    "placketBack",
                    "placketFrontRight",
                    "placketFrontLeft"
                ],
                "area3": [
                    "*.whafb.area3"
                ],
                "area4": [
                    "*.whafb.area4"
                ],
                "area5": [
                    "*.whafb.area5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Top Insert",
                "area4": "Middle Insert",
                "area5": "Bottom Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WHAFB",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WHAFBG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WHAFBSL": {
        "icon": "assets/product-icons/WHAFBSL.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WHAFBSL",
        "hasAssets": true,
        "modelId": "WFBSL",
        "textureId": "WFBSL",
        "asset": {
            "isFullButton": true,
            "isSleeveless": true,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "placketLeft",
                    "placketRight",
                    "placketBack"
                ],
                "area3": [
                    "*.whafbsl.area3"
                ],
                "area4": [
                    "*.whafbsl.area4"
                ],
                "area5": [
                    "*.whafbsl.area5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Top Insert",
                "area4": "Middle Insert",
                "area5": "Bottom Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WHAFBSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WHAFBSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WHOUSFB": {
        "icon": "assets/product-icons/WHOUSFB.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WHOUSFB",
        "hasAssets": true,
        "modelId": "FXCFJ-SS",
        "textureId": "FXCFJ-SS",
        "asset": {
            "isFullButton": true,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area5",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.whousfb.color1"
                ],
                "area2": [
                    "*.whousfb.color2"
                ],
                "area3": [
                    "*.whousfb.color3"
                ],
                "area4": [
                    "*.whousfb.color4"
                ],
                "area5": [
                    "*.whousfb.color5"
                ],
                "area6": [
                    "*.whousfb.color6"
                ],
                "area7": [
                    "*.whousfb.color7"
                ]
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors"
            },
            "titles": {
                "area1": "Bottom Jersey and Stripes",
                "area2": "Secondary Stripe",
                "area3": "Tertiary Stripe",
                "area4": "Sleeve End and Middle Stripe",
                "area5": "Chest",
                "area6": "Upper Collar",
                "area7": "Lower Collar"
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WHOUSFB",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WHOUSFBG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WHOUSFBSL": {
        "icon": "assets/product-icons/WHOUSFBSL.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WHOUSFBSL",
        "hasAssets": true,
        "modelId": "WFBSL",
        "textureId": "WFBSL",
        "asset": {
            "isFullButton": true,
            "isSleeveless": true,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area5",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.whousfbsl.area1"
                ],
                "area2": [
                    "*.whousfbsl.area2"
                ],
                "area3": [
                    "*.whousfbsl.area3"
                ],
                "area4": [
                    "*.whousfbsl.area4"
                ],
                "area5": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "placketLeft",
                    "placketRight",
                    "placketBack"
                ],
                "area6": [
                    "*.whousfbsl.area6"
                ],
                "area7": [
                    "*.whousfbsl.area7"
                ]
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors"
            },
            "titles": {
                "area1": "Bottom Jersey and Stripes",
                "area2": "Secondary Stripes",
                "area3": "Tertiary Stripes",
                "area4": "Middle Stripe",
                "area5": "Chest",
                "area6": "Upper Collar",
                "area7": "Lower Collar"
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WHOUSFBSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WHOUSFBSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WHRDFB": {
        "icon": "assets/product-icons/WHRDFB.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WHRDFB",
        "hasAssets": true,
        "modelId": "FXCFJ-SS",
        "textureId": "FXCFJ-SS",
        "asset": {
            "isFullButton": true,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "back",
                    "frontRight",
                    "frontLeft",
                    "placketLeft",
                    "placketRight",
                    "collar"
                ],
                "area3": [
                    "*.whrdfb.color3"
                ],
                "area4": [
                    "*.whrdfb.color4"
                ],
                "area5": [
                    "*.ribKnitPlacket"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "jerseyBodyNoFade",
                "area4": "noFade",
                "area5": "pattern",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Shoulder and Sleeve",
                "area4": "Side Inserts",
                "area5": "Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WHRDFB",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WHRDFBG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WHRDFBSL": {
        "icon": "assets/product-icons/WHRDFBSL.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WHRDFBSL",
        "hasAssets": true,
        "modelId": "WFBSL",
        "textureId": "WFBSL",
        "asset": {
            "isFullButton": true,
            "isSleeveless": true,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "placketLeft",
                    "placketRight",
                    "placketBack"
                ],
                "area3": [
                    "*.whrdfbsl.area3"
                ],
                "area4": [
                    "*.whrdfbsl.area4"
                ],
                "area5": [
                    "*.ribKnitPlacket"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "standard",
                "area4": "standard",
                "area5": "pattern",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Shoulder",
                "area4": "Side Inserts",
                "area5": "Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WHRDFBSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WHRDFBSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WMBFB": {
        "icon": "assets/product-icons/WMBFB.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WMBFB",
        "hasAssets": true,
        "modelId": "FXCFJ-SS",
        "textureId": "FXCFJ-SS",
        "asset": {
            "isFullButton": true,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.wmbfb.color1"
                ],
                "area2": [
                    "back",
                    "frontRight",
                    "frontLeft",
                    "placketRight",
                    "placketLeft",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "*.wmbfb.color3"
                ],
                "area4": [
                    "*.wmbfb.color4"
                ],
                "area5": [
                    "*.wmbfb.color5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Sleeve Insert",
                "area4": "Side Inserts",
                "area5": "Front Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WMBFB",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WMBFBG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WMBFBSL": {
        "icon": "assets/product-icons/WMBFBSL.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WMBFBSL",
        "hasAssets": true,
        "modelId": "WFBSL",
        "textureId": "WFBSL",
        "asset": {
            "isFullButton": true,
            "isSleeveless": true,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.wmbfbsl.area1"
                ],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "placketLeft",
                    "placketRight",
                    "placketBack"
                ],
                "area3": [],
                "area4": [
                    "*.wmbfbsl.area4"
                ],
                "area5": [
                    "*.wmbfbsl.area5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": null,
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": null,
                "area4": "Side Inserts",
                "area5": "Front Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WMBFBSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WMBFBSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WOAKFB": {
        "icon": "assets/product-icons/WOAKFB.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WOAKFB",
        "hasAssets": true,
        "modelId": "FXCFJ-SS",
        "textureId": "FXCFJ-SS",
        "asset": {
            "isFullButton": true,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "back",
                    "frontRight",
                    "frontLeft",
                    "collar",
                    "placketRight",
                    "placketLeft",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "*.woakfb.color3"
                ],
                "area4": [
                    "*.woakfb.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Neck/Front Outer & Sleeve Outer",
                "area4": "Neck/Front Middle & Sleeve Middle",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WOAKFB",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WOAKFBG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WOAKFBSL": {
        "icon": "assets/product-icons/WOAKFBSL.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WOAKFBSL",
        "hasAssets": true,
        "modelId": "WFBSL",
        "textureId": "WFBSL",
        "asset": {
            "isFullButton": true,
            "isSleeveless": true,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "placketLeft",
                    "placketRight",
                    "placketBack"
                ],
                "area3": [
                    "*.woakfbsl.area3"
                ],
                "area4": [
                    "*.woakfbsl.area4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Neck/Front Outer & Armhole Outer",
                "area4": "Neck/Front Middle & Armhole Middle",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WOAKFBSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WOAKFBSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WWINGFB": {
        "icon": "assets/product-icons/WWINGFB.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WWINGFB",
        "hasAssets": true,
        "modelId": "WARZJ",
        "textureId": "WARZJ",
        "asset": {
            "isFullButton": true,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.wwingfb.area1"
                ],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back",
                    "placketBack",
                    "placketFrontRight",
                    "placketFrontLeftt"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.wwingfb.area4"
                ],
                "area5": [
                    "*.wwingfb.area5"
                ],
                "area6": [
                    "*.wwingfb.area6"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": "Sleeve Piping",
                "area2": "Body",
                "area3": "Lower Sleeve",
                "area4": "Upper Sleeve",
                "area5": "Side Insert",
                "area6": "Front Placket",
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WWINGFB",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WWINGFBG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WWINGFBSL": {
        "icon": "assets/product-icons/WWINGFBSL.jpg?2014-09-30",
        "category": "sublimatedFullButtonJersey",
        "optionList": [
            "OP1",
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WWINGFBSL",
        "hasAssets": true,
        "modelId": "WFBSL",
        "textureId": "WFBSL",
        "asset": {
            "isFullButton": true,
            "isSleeveless": true,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.wwingfbsl.area1"
                ],
                "area2": [
                    "frontLeft",
                    "frontRight",
                    "back"
                ],
                "area3": [
                    "*.wwingfbsl.area3"
                ],
                "area4": [
                    "*.wwingfbsl.area4",
                    "placketBack"
                ],
                "area5": [
                    "*.wwingfbsl.area5"
                ],
                "area6": [
                    "*.wwingfbsl.area6",
                    "placketLeft",
                    "placketRight"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": "Shoulder Piping",
                "area2": "Body",
                "area3": "Armhole Insert",
                "area4": "Shoulder and Upper Collar",
                "area5": "Side Insert",
                "area6": "Front Facing",
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": true,
            "OP2": true,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WWINGFBSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WWINGFBSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WARZ2B": {
        "icon": "assets/product-icons/WARZ2B.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WARZ2B",
        "hasAssets": true,
        "modelId": "CW2B",
        "textureId": "CW2B",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "back",
                    "placketRight",
                    "placketLeft",
                    "placketBack",
                    "front",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "*.warz2b.color3"
                ],
                "area4": [
                    "*.warz2b.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Side Insert Accents",
                "area4": "Sleeve Accents and Across Collar",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WARZ2B",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WARZ2BG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WARZ2BSL": {
        "icon": "assets/product-icons/WARZ2BSL.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WARZ2BSL",
        "hasAssets": true,
        "modelId": "W2BSL",
        "textureId": "W2BSL",
        "asset": {
            "isFullButton": false,
            "isSleeveless": true,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside"
                ],
                "area3": [
                    "*.warz2bsl.area3"
                ],
                "area4": [
                    "*.warz2bsl.area4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Side Insert Accents",
                "area4": "Shoulder Accents and Across Collar",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WARZ2BSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WARZ2BSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WBB2B": {
        "icon": "assets/product-icons/WBB2B.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WBB2B",
        "hasAssets": true,
        "modelId": "CW2B",
        "textureId": "CW2B",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "placketBack",
                    "placketRight",
                    "placketLeft"
                ],
                "area3": [
                    "*.wbb2b.area3"
                ],
                "area4": [
                    "*.wbb2b.area4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Border Stripes Design",
                "area4": "Middle Stripe on Body / Sleeve Border",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": "m",
            "decorationPackages": {
                "adult": [
                    "fullSublimationCHI"
                ],
                "youth": [
                    "fullSublimationCHI"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WBB2B",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WBB2BG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WBB2BSL": {
        "icon": "assets/product-icons/WBB2BSL.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WBB2BSL",
        "hasAssets": true,
        "modelId": "W2BSL",
        "textureId": "WBB2BSL",
        "asset": {
            "isFullButton": false,
            "isSleeveless": true,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside"
                ],
                "area3": [
                    "*.wbb2bsl.area3"
                ],
                "area4": [
                    "*.wbb2bsl.area4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Border Stripes Design",
                "area4": "Middle Stripe on Body",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": "m",
            "decorationPackages": {
                "adult": [
                    "fullSublimationCHI"
                ],
                "youth": [
                    "fullSublimationCHI"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WBB2BSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WBB2BSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WCHI2B": {
        "icon": "assets/product-icons/WCHI2B.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WCHI2B",
        "hasAssets": true,
        "modelId": "WHRD2B",
        "textureId": "WCHI2B",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "back",
                    "front",
                    "sleeveRight",
                    "sleeveLeft",
                    "placketRight",
                    "placketLeft",
                    "collar"
                ],
                "area3": [
                    "*.wchi2b.color3"
                ],
                "area4": [
                    "*.wchi2b.color4"
                ],
                "area5": [
                    "*.wchi2b.color5"
                ],
                "area6": [
                    "*.wchi2b.color6"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Middle Stripe",
                "area4": "Border Stripes",
                "area5": "Upper Collar",
                "area6": "Lower Collar",
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": "m",
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WCHI2B",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WCHI2BG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WCHI2BSL": {
        "icon": "assets/product-icons/WCHI2BSL.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WCHI2BSL",
        "hasAssets": true,
        "modelId": "W2BSL",
        "textureId": "WCHI2BSL",
        "asset": {
            "isFullButton": false,
            "isSleeveless": true,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.wchi2bsl.area3"
                ],
                "area4": [
                    "*.wchi2bsl.area4"
                ],
                "area5": [
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside"
                ],
                "area6": [
                    "*.wchi2bsl.area6"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Middle Stripe",
                "area4": "Border Stripes",
                "area5": "Upper Collar",
                "area6": "Lower Collar",
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": "m",
            "decorationPackages": {
                "adult": [
                    "fullSublimationCHI"
                ],
                "youth": [
                    "fullSublimationCHI"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WCHI2BSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WCHI2BSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WDF2B": {
        "icon": "assets/product-icons/WDF2B.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WDF2B",
        "hasAssets": true,
        "modelId": "CW2B",
        "textureId": "CW2B",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "placketBack",
                    "placketRight",
                    "placketLeft"
                ],
                "area3": [
                    "*.wdf2b.area3"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Upper Body",
                "area3": "Lower Body",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WDF2B",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WDF2BG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WDF2BSL": {
        "icon": "assets/product-icons/WDF2BSL.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WDF2BSL",
        "hasAssets": true,
        "modelId": "W2BSL",
        "textureId": "W2BSL",
        "asset": {
            "isFullButton": false,
            "isSleeveless": true,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside"
                ],
                "area3": [
                    "*.wdf2bsl.area3"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Upper Body",
                "area3": "Lower Body",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WDF2BSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WDF2BSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WHA2B": {
        "icon": "assets/product-icons/WHA2B.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WHA2B",
        "hasAssets": true,
        "modelId": "CW2B",
        "textureId": "CW2B",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight",
                    "placketRight",
                    "placketLeft",
                    "placketBack",
                    "button"
                ],
                "area3": [
                    "*.wha2b.area3"
                ],
                "area4": [
                    "*.wha2b.area4"
                ],
                "area5": [
                    "*.wha2b.area5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Top Insert",
                "area4": "Middle Insert",
                "area5": "Bottom Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WHA2B",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WHA2BG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WHA2BSL": {
        "icon": "assets/product-icons/WHA2BSL.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WHA2BSL",
        "hasAssets": true,
        "modelId": "W2BSL",
        "textureId": "W2BSL",
        "asset": {
            "isFullButton": false,
            "isSleeveless": true,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside"
                ],
                "area3": [
                    "*.wha2bsl.area3"
                ],
                "area4": [
                    "*.wha2bsl.area4"
                ],
                "area5": [
                    "*.wha2bsl.area5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Top Insert",
                "area4": "Middle Insert",
                "area5": "Bottom Insert",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WHA2BSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WHA2BSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WHOUS2B": {
        "icon": "assets/product-icons/WHOUS2B.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WHOUS2B",
        "hasAssets": true,
        "modelId": "WHRD2B",
        "textureId": "WHRD2B",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area5",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.whous2b.color1"
                ],
                "area2": [
                    "*.whous2b.color2"
                ],
                "area3": [
                    "*.whous2b.color3"
                ],
                "area4": [
                    "*.whous2b.color4"
                ],
                "area5": [
                    "*.whous2b.color5"
                ],
                "area6": [
                    "*.whous2b.color6"
                ],
                "area7": [
                    "*.whous2b.color7"
                ]
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors"
            },
            "titles": {
                "area1": "Bottom Jersey and Stripes",
                "area2": "Secondary Stripe",
                "area3": "Tertiary Stripe",
                "area4": "Sleeve End and Middle Stripe",
                "area5": "Chest",
                "area6": "Upper Collar",
                "area7": "Lower Collar"
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WHOUS2B",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WHOUS2BG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WHOUS2BSL": {
        "icon": "assets/product-icons/WHOUS2BSL.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WHOUS2BSL",
        "hasAssets": true,
        "modelId": "W2BSL",
        "textureId": "W2BSL",
        "asset": {
            "isFullButton": false,
            "isSleeveless": true,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area5",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.whous2bsl.area1"
                ],
                "area2": [
                    "*.whous2bsl.area2"
                ],
                "area3": [
                    "*.whous2bsl.area3"
                ],
                "area4": [
                    "*.whous2bsl.area4"
                ],
                "area5": [
                    "front",
                    "back"
                ],
                "area6": [
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside"
                ],
                "area7": [
                    "*.whous2bsl.area7"
                ]
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors"
            },
            "titles": {
                "area1": "Bottom Jersey and Stripes",
                "area2": "Secondary Stripes",
                "area3": "Tertiary Stripes",
                "area4": "Middle Stripe",
                "area5": "Chest",
                "area6": "Upper Collar",
                "area7": "Lower Collar"
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WHOUS2BSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WHOUS2BSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WHRD2B": {
        "icon": "assets/product-icons/WHRD2B.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WHRD2B",
        "hasAssets": true,
        "modelId": "WHRD2B",
        "textureId": "WHRD2B",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.whrd2b.color3"
                ],
                "area4": [
                    "*.whrd2b.color4"
                ],
                "area5": [
                    "*.ribKnitPlacket"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "jerseyBodyNoFade",
                "area4": "noFade",
                "area5": "pattern",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Shoulder and Sleeve",
                "area4": "Side Inserts",
                "area5": "Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WHRD2B",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WHRD2BG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WHRD2BSL": {
        "icon": "assets/product-icons/WHRD2BSL.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WHRD2BSL",
        "hasAssets": true,
        "modelId": "W2BSL",
        "textureId": "W2BSL",
        "asset": {
            "isFullButton": false,
            "isSleeveless": true,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.whrd2bsl.area3"
                ],
                "area4": [
                    "*.whrd2bsl.area4"
                ],
                "area5": [
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "standard",
                "area4": "standard",
                "area5": "pattern",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Shoulder",
                "area4": "Side Inserts",
                "area5": "Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WHRD2BSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WHRD2BSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WMB2B": {
        "icon": "assets/product-icons/WMB2B.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WMB2B",
        "hasAssets": true,
        "modelId": "WHRD2B",
        "textureId": "WHRD2B",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.wmb2b.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "*.wmb2b.color3"
                ],
                "area4": [
                    "*.wmb2b.color4"
                ],
                "area5": [
                    "*.wmb2b.color5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": "Sleeve Insert",
                "area4": "Side Inserts",
                "area5": "Front Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WMB2B",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WMB2BG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WMB2BSL": {
        "icon": "assets/product-icons/WMB2BSL.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WMB2BSL",
        "hasAssets": true,
        "modelId": "W2BSL",
        "textureId": "W2BSL",
        "asset": {
            "isFullButton": false,
            "isSleeveless": true,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [],
                "area4": [
                    "*.wmb2bsl.area4"
                ],
                "area5": [
                    "*.wmb2bsl.area5"
                ],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": null,
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": "Collar",
                "area2": "Body",
                "area3": null,
                "area4": "Side Inserts",
                "area5": "Front Collar",
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WMB2BSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WMB2BSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WOAK2B": {
        "icon": "assets/product-icons/WOAK2B.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WOAK2B",
        "hasAssets": true,
        "modelId": "WHRD2B",
        "textureId": "WHRD2B",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "*.woak2b.color3"
                ],
                "area4": [
                    "*.woak2b.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Neck Outer & Sleeve Outer",
                "area4": "Neck Middle & Sleeve Middle",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WOAK2B",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WOAK2BG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WOAK2BSL": {
        "icon": "assets/product-icons/WOAK2BSL.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WOAK2BSL",
        "hasAssets": true,
        "modelId": "W2BSL",
        "textureId": "W2BSL",
        "asset": {
            "isFullButton": false,
            "isSleeveless": true,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.woak2bsl.area3",
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside"
                ],
                "area4": [
                    "*.woak2bsl.area4"
                ],
                "area5": [],
                "area6": [],
                "area7": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Neck/Front Outer & Armhole Outer",
                "area4": "Neck/Front Middle & Armhole Middle",
                "area5": null,
                "area6": null,
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": false,
            "OP3": false,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WOAK2BSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WOAK2BSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WWING2B": {
        "icon": "assets/product-icons/WWING2B.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WWING2B",
        "hasAssets": true,
        "modelId": "CW2B",
        "textureId": "CW2B",
        "asset": {
            "isFullButton": false,
            "isSleeveless": false,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.wwing2b.area1"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.wwing2b.area4"
                ],
                "area5": [
                    "*.wwing2b.area5"
                ],
                "area6": [
                    "placketBack",
                    "placketRight",
                    "placketLeft"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": "Sleeve Piping",
                "area2": "Body",
                "area3": "Lower Sleeve",
                "area4": "Upper Sleeve",
                "area5": "Side Insert",
                "area6": "Collar",
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WWING2B",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WWING2BG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WWING2BSL": {
        "icon": "assets/product-icons/WWING2BSL.jpg?2014-09-30",
        "category": "sublimatedTwoButtonJersey",
        "optionList": [
            "OP2",
            "OP3",
            "logoColor",
            "buttonColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WWING2BSL",
        "hasAssets": true,
        "modelId": "W2BSL",
        "textureId": "W2BSL",
        "asset": {
            "isFullButton": false,
            "isSleeveless": true,
            "hasButtons": true
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.wwing2bsl.area1"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.wwing2bsl.area3"
                ],
                "area4": [
                    "*.wwing2bsl.area4"
                ],
                "area5": [
                    "*.wwing2bsl.area5"
                ],
                "area6": [
                    "collar",
                    "collarInside",
                    "placketRight",
                    "placketLeft",
                    "placketRightInside",
                    "placketLeftInside"
                ],
                "area7": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null
            },
            "titles": {
                "area1": "Shoulder Piping",
                "area2": "Body",
                "area3": "Armhole Insert",
                "area4": "Shoulder",
                "area5": "Side Insert",
                "area6": "Collar",
                "area7": null
            },
            "colorSets": [
                "sublimatedProJerseys",
                "sublimatedCollegiateJerseys",
                "sublimatedTravelballJerseys"
            ]
        },
        "options": {
            "OP1": false,
            "OP2": true,
            "OP3": true,
            "logoColor": "sublimated",
            "buttonColor": true
        },
        "decoration": {
            "teamnameAlign": null,
            "decorationPackages": {
                "adult": [
                    "fullSublimation"
                ],
                "youth": [
                    "fullSublimation"
                ]
            },
            "disabledDecos": []
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WWING2BSL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WWING2BSLG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WRB150": {
        "icon": "assets/product-icons/WRB150.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [
            "OP4"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WRB150",
        "modelId": "WB150",
        "textureId": "WB150",
        "asset": {
            "": null
        },
        "colorAreas": {
            "layers": {
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "loopSide",
                    "loopBack",
                    "loopSmall",
                    "button"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "WB150"
            ]
        },
        "options": {
            "hasPlacement27": false,
            "RIBWST": false,
            "OP4": true,
            "OP15": false,
            "OP4_2": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WRB150",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WRB150G",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WLNCH": {
        "icon": "assets/product-icons/WLNCH.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [
            "OP4"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WLNCH",
        "modelId": "WB150",
        "textureId": "WB150",
        "asset": {
            "": null
        },
        "colorAreas": {
            "layers": {
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "loopSide",
                    "loopBack",
                    "loopSmall",
                    "button"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "WLNCH"
            ]
        },
        "options": {
            "hasPlacement27": false,
            "RIBWST": false,
            "OP4": true,
            "OP15": false,
            "OP4_2": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WLNCH",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WLNCHG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WBDP": {
        "icon": "assets/product-icons/WBDP.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "WBDP",
        "modelId": "WBDP",
        "textureId": "WBDP",
        "asset": {
            "": null
        },
        "colorAreas": {
            "layers": {
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "button"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "WBDP"
            ]
        },
        "options": {
            "hasPlacement27": false,
            "RIBWST": false,
            "OP4": false,
            "OP15": false,
            "OP4_2": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WLNCH",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WLNCHG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "WYP": {
        "icon": "assets/product-icons/WYP.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [
            "OP4"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "WYP",
        "modelId": "WYP",
        "textureId": "WYP",
        "asset": {
            "": null
        },
        "colorAreas": {
            "layers": {
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "insertUpperLeft",
                    "insertUpperRight",
                    "insertLowerLeft",
                    "insertLowerRight",
                    "loop",
                    "loopSmall"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Entire Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "WYP"
            ]
        },
        "options": {
            "hasPlacement27": false,
            "RIBWST": false,
            "OP4": true,
            "OP15": false,
            "OP4_2": false,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "WYP",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "WYPG",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "FXCIP": {
        "icon": "assets/product-icons/FXCIP.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "RIBWST",
            "OP4",
            "OP15",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "FXCIP",
        "modelId": "FXCIP",
        "textureId": "FXCIP",
        "asset": {
            "": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "tunnelLoop",
                    "tunnelLoopMiddle",
                    "loop"
                ],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "button"
                ],
                "area3": [
                    "insertUpperRight",
                    "insertUpperLeft"
                ],
                "area4": [
                    "insertLowerRight",
                    "insertLowerLeft"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Belt Loops",
                "area2": "Body",
                "area3": "Top Inserts",
                "area4": "Bottom Insert",
                "area5": null
            },
            "colorSets": [
                "customProPants",
                "customCollegiatePants"
            ]
        },
        "options": {
            "hasPlacement27": false,
            "RIBWST": true,
            "OP4": true,
            "OP15": true,
            "OP4_2": false,
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FXCIP",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FXCIPG",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Fill-in through 5/1/20"
    },
    "FXCLP": {
        "icon": "assets/product-icons/FXCLP.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "RIBWST",
            "OP4",
            "OP15",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "FXCLP",
        "modelId": "FXCLP",
        "textureId": "FXCLP",
        "asset": {
            "": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "loopSide",
                    "loopBack",
                    "loopSmall"
                ],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "button"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": "Belt Loops",
                "area2": "Body",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "customProPants",
                "customCollegiatePants"
            ]
        },
        "options": {
            "hasPlacement27": false,
            "RIBWST": true,
            "OP4": true,
            "OP15": true,
            "OP4_2": false,
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FXCLP",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FXCLPG",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Fill-in through 5/1/20"
    },
    "FXCLP2": {
        "icon": "assets/product-icons/FXCLP2.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "RIBWST",
            "OP4",
            "OP15",
            "OP4_2",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "FXCLP2",
        "modelId": "FXCLP2",
        "textureId": "FXCLP2",
        "asset": {
            "": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "loopSide",
                    "loopBack",
                    "loopSmall"
                ],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "button"
                ],
                "area3": [
                    "insertRight",
                    "insertLeft"
                ],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": "Belt Loops",
                "area2": "Body",
                "area3": "Side Insert",
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "customProPants",
                "customCollegiatePants"
            ]
        },
        "options": {
            "hasPlacement27": false,
            "RIBWST": true,
            "OP4": true,
            "OP15": true,
            "OP4_2": true,
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FXCLP2",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FXCLPG2",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Fill-in through 5/1/20"
    },
    "FXCLP3": {
        "icon": "assets/product-icons/FXCLP3.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "RIBWST",
            "OP4",
            "OP15",
            "OP4_2",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "code": "FXCLP3",
        "modelId": "FXCLP3",
        "textureId": "FXCLP3",
        "asset": {
            "": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "loopSide",
                    "loopBack",
                    "loopSmall"
                ],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "button"
                ],
                "area3": [
                    "insertRight",
                    "insertLeft"
                ],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": "Belt Loops",
                "area2": "Body",
                "area3": "Side Insert",
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "customProPants",
                "customCollegiatePants"
            ]
        },
        "options": {
            "hasPlacement27": false,
            "RIBWST": true,
            "OP4": true,
            "OP15": true,
            "OP4_2": true,
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FXCLP3",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FXCLPG3",
                "price": null,
                "note": null
            }
        },
        "skuNote": "Fill-in through 5/1/20"
    },
    "FXCIPVF": {
        "icon": "assets/product-icons/FXCIPVF.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "RIBWST",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FXCIPVF",
        "modelId": "FXCIP",
        "textureId": "FXCIP",
        "asset": {
            "": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "tunnelLoop",
                    "tunnelLoopMiddle",
                    "loop"
                ],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "belt",
                    "belt1",
                    "button"
                ],
                "area3": [
                    "insertUpperRight",
                    "insertUpperLeft"
                ],
                "area4": [
                    "insertLowerRight",
                    "insertLowerLeft"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "pantBody",
                "area2": "pantBody",
                "area3": "standard",
                "area4": "standard",
                "area5": null
            },
            "titles": {
                "area1": "Belt Loops",
                "area2": "Body",
                "area3": "Top Insert",
                "area4": "Bottom Insert",
                "area5": null
            },
            "colorSets": [
                "sublimatedProPants",
                "sublimatedCollegiatePants"
            ]
        },
        "options": {
            "hasPlacement27": false,
            "RIBWST": true,
            "OP4": false,
            "OP15": false,
            "OP4_2": false,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FXCIPVF",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FXCIPGVF",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "FXCLPVF": {
        "icon": "assets/product-icons/FXCLPVF.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "RIBWST",
            "OP4",
            "OP15",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FXCLPVF",
        "modelId": "FXCLP",
        "textureId": "FXCLP",
        "asset": {
            "": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "loopSide",
                    "loopBack",
                    "loopSmall"
                ],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "belt",
                    "belt1",
                    "button"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": "pantBody",
                "area2": "pantBody",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": "Belt Loops",
                "area2": "Body",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "sublimatedProPants",
                "sublimatedCollegiatePants"
            ]
        },
        "options": {
            "hasPlacement27": false,
            "RIBWST": true,
            "OP4": true,
            "OP15": true,
            "OP4_2": false,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FXCLPVF",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FXCLPGVF",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "FXCLP2VF": {
        "icon": "assets/product-icons/FXCLP2VF.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "RIBWST",
            "OP4",
            "OP15",
            "OP4_2",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FXCLP2VF",
        "modelId": "FXCLP2",
        "textureId": "FXCLP2",
        "asset": {
            "": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "loopSide",
                    "loopBack",
                    "loopSmall"
                ],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "belt",
                    "belt1",
                    "button"
                ],
                "area3": [
                    "insertRight",
                    "insertLeft"
                ],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": "pantBody",
                "area2": "pantBody",
                "area3": "standard",
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": "Belt Loops",
                "area2": "Body",
                "area3": "Side Insert",
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "sublimatedProPants",
                "sublimatedCollegiatePants"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "RIBWST": true,
            "OP4": true,
            "OP15": true,
            "OP4_2": true,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FXCLP2VF",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FXCLPG2VF",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    },
    "FXCLP3VF": {
        "icon": "assets/product-icons/FXCLP3VF.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "RIBWST",
            "OP4",
            "OP15",
            "OP4_2",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "code": "FXCLP3VF",
        "modelId": "FXCLP3",
        "textureId": "FXCLP3",
        "asset": {
            "": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "loopSide",
                    "loopBack",
                    "loopSmall"
                ],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "belt",
                    "belt1",
                    "button"
                ],
                "area3": [
                    "insertRight",
                    "insertLeft"
                ],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": "pantBody",
                "area2": "pantBody",
                "area3": "standard",
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": "Belt Loops",
                "area2": "Body",
                "area3": "Side Inserts",
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "sublimatedProPants",
                "sublimatedCollegiatePants"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "RIBWST": true,
            "OP4": true,
            "OP15": true,
            "OP4_2": true,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FXCLP3VF",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "FXCLPG3VF",
                "price": null,
                "note": null
            }
        },
        "skuNote": null
    }
}